import React, { useContext, useState } from "react";
import _ from "lodash";

import { Button, Typography, OutlinedInput, Paper } from "@mui/material";

import EnvironmentDisplay from "../../Kiosk/Components/Logo/EnvironmentDisplay";

import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

let env = require("../../../env");

function ResetPasswordDone({ authenticate, props }) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "lightgrey" //Should be consignee Green
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //height: "20vh",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2vw"
        }}
      >
        <EnvironmentDisplay />
        <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
          Consignaction Kiosk
        </Typography>{" "}
      </div>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "4vw"
        }}
      >
        <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
          Password Reset Successful!
        </Typography>

        {/* <Typography variant="body2" style={{ marginTop: theme.spacing(0.5) }}>
          Please check your email for further instructions on your password
          reset.
        </Typography> */}
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            navigate("/login");
          }}
          style={{ marginTop: theme.spacing(2) }}
        >
          Return to Login
        </Button>
      </Paper>
      <span
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2vw",
          color: "grey"
        }}
      >
        {env.REACT_APP_VERSION}
      </span>
    </div>
  );
}

export default ResetPasswordDone;
