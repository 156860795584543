import React, { useContext, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  Paper,
  FormControlLabel,
  Checkbox
} from "@mui/material";

import EnvironmentDisplay from "../../Kiosk/Components/Logo/EnvironmentDisplay";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import HttpContext from "../../../contexts/HTTP/HttpContext";

let env = require("../../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  403: "Your account has been disabled. Please contact support for assistance.",
  404: "Invalid credentials provided"
};

function Register({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberme: "false"
    },
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      const res = await post("/login", values);
      if (res.status >= 400) {
        setLoginError(ERRORS[res.status]);
      } else {
        authenticate(res);
        setLoginError("");
      }

      setLoading(false);
    }
  });

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "lightgrey" //Should be consignee Green
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //height: "20vh",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2vw"
        }}
      >
        <EnvironmentDisplay />
        <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
          Consignaction Kiosk
        </Typography>{" "}
      </div>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "270px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "4vw"
        }}
      >
        <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
          Kiosk Admin Login
        </Typography>

        <TextField
          fullWidth
          style={{ margin: theme.spacing(0.75) }}
          variant="outlined"
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled={loading}
          onKeyDown={handleKeyDown}
        />
        <FormControl
          fullWidth
          variant="outlined"
          disabled={loading}
          style={{ margin: theme.spacing(0.75) }}
        >
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            label="Password"
            value={formik.values.password}
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Link
            to="/forgetpassword"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Forget Password?
          </Link>

          <FormControlLabel
            control={
              <Checkbox
                id="rememberme"
                name="rememberme"
                label="Remember Me"
                value={formik.values.rememberme}
                onChange={formik.handleChange}
              />
            }
            label="Remember Me"
            style={{ marginTop: theme.spacing(0.5) }}
          />
        </FormControl>

        <Typography
          variant="body2"
          style={{
            color: "red",
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
            textAlign: "center"
          }}
        >
          {loginError}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          onClick={formik.handleSubmit}
          //style={{ marginTop: theme.spacing(2) }}
        >
          Login
        </Button>
      </Paper>
      <span
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2vw",
          color: "grey"
        }}
      >
        {env.REACT_APP_VERSION}
      </span>
    </div>
  );
}

export default Register;
