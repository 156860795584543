import React from "react";
import { Translate } from "react-i18nify";

import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

import { Delete } from "@mui/icons-material";

import { kioskstyles } from "./../../../../kioskstyles";
import "./../../../../font.css";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
function KioskIDButton(props) {
  const { customerName, label } = props;

  const kioskID = localStorage.getItem("kioskID");

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCopy = event => {
    navigator.clipboard
      .writeText(kioskID)
      .then(() => {})
      .catch(err => {});
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCopy}>
        COPY
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div
      class="fontSecondaryText"
      style={{ position: "absolute", top: "1vh", left: "1vh" }}
    >
      <IconButton onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={kioskID}
        action={action}
      />
    </div>
  );
}

export default KioskIDButton;
