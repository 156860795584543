import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";

import { WORKFLOW_MODES } from "../../../../../constants";

import { useTheme } from "@mui/material/styles";

export default function WorkflowDropdown({
  initialValue,
  handleSetWorkflowModeKiosk,
  sessionID,
  kioskID
}) {
  const theme = useTheme();

  const [value, setValue] = React.useState(initialValue);

  console.log("WorkflowDropdown value:", value);

  useEffect(() => {
    (async () => {
      setValue(initialValue);
    })();
  }, [initialValue]);

  return (
    <React.Fragment>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          displayEmpty
          value={value}
          onChange={async event => {
            let newvalue = await handleSetWorkflowModeKiosk(
              sessionID,
              kioskID,
              event.target.value
            );
            setValue(newvalue);
          }}
          label="Workflow Mode"
        >
          <MenuItem value={WORKFLOW_MODES.BAGS_AND_LABELS}>
            Bag and Labels
          </MenuItem>
          <MenuItem value={WORKFLOW_MODES.BAG_ONLY}>Bags Only</MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  );
}
