// import * as React from "react";
import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "./../../../../hooks/useSnackbar";
import _ from "lodash";

import {
  Autocomplete,
  TextField,
  Snackbar,
  Alert,
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  OutlinedInput,
  FormHelperText
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";
import AlertDialog from "../../../../components/Dialogs/AlertDialog";

import { useFormik } from "formik";
import * as Yup from "yup";

import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../../constants";

import HttpContext from "../../../../contexts/HTTP/HttpContext";

function CreateKioskGroupDialog(props) {
  const theme = useTheme();
  const { post, get } = useContext(HttpContext);

  const { open, handleClose, handleRefresh } = props;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { openSnackbar } = useSnackbar();

  useEffect(() => {}, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is a required field")
        .matches(EMAIL_REGEX, "Email format invalid"),
      password: Yup.string()
        .required("Password is a required field")
        .max(32, "Password can contain at most 32 characters")
        .matches(
          PASSWORD_REGEX,
          "Password must contain at least one uppercase character, one lowercase character, and one number"
        )
    }),
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      const res = await post("/register", values);

      if (res.status === 200) {
        openSnackbar({
          open: true,
          message: "Added successfully",
          variant: "alert",
          alert: {
            color: "success"
          },
          close: false
        });
        handleClose();
        await handleRefresh();
      } else {
        openSnackbar({
          open: true,
          message: "Duplicate entry - already exists",
          variant: "alert",
          alert: {
            color: "error"
          },
          close: false
        });
      }

      setLoading(false);
    }
  });

  return (
    <>
      <AlertDialog
        open={open}
        handleConfirm={formik.handleSubmit}
        handleClose={handleClose}
        title="Add a new User"
        confirmButtonName="Confirm"
        closeButtonName="Cancel"
      >
        <br />

        <InputLabel htmlFor="Email">Email</InputLabel>
        <OutlinedInput
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled={loading}
          error={!_.isEmpty(formik.errors.email)}
          helperText={formik.errors.email}
        />
        <div>{formik.errors.email}</div>
        <br />
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          fullWidth
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          type={showPassword ? "text" : "password"}
          onChange={formik.handleChange}
          error={!_.isEmpty(formik.errors.password)}
          helperText={formik.errors.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={e => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div>{formik.errors.password}</div>
      </AlertDialog>
    </>
  );
}

export default CreateKioskGroupDialog;
