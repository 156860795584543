import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";

import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import ClearIcon from "@mui/icons-material/Clear";

import ListIcon from "@mui/icons-material/List";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";

import Tooltip from "@mui/material/Tooltip";

import KioskRowErrorLink from "./KioskRowErrorLink";
import KioskRowClearErrorButton from "./KioskRowClearErrorButton";
import KioskRowSessionWorkflows from "./KioskRowSessionWorkflows";

import { getFrontendString } from "./../../../../utils/adminconsts";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField,
  Badge
} from "@mui/material";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import SyncIcon from "@mui/icons-material/Sync";

function KioskRow({
  row,
  showDisabledEntries,
  handleActionViewKiosks,
  handleActionSetMode,
  handleActionClearError,
  handleActionCollectorSync,
  handleActionEdit
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (showDisabledEntries == false && row.active == false) {
    return <></>;
  }

  return (
    <TableRow key={row.locationCode}>
      {/* Status */}
      <TableCell align="center">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          {row.lastSyncedNotFound ? (
            <Tooltip
              title={
                "sync issue: This device (" +
                row.locationCode +
                " - " +
                row.subscriptionID +
                ") was not found in the Consignaction Staging during synchronization. The device may need to be registered or reconfigured in Consignaction Staging to ensure proper connection."
              }
              arrow
              placement="top"
            >
              <WarningIcon color="warning" />
            </Tooltip>
          ) : (
            <></>
          )}

          {row.active ? (
            <Circle style={{ color: "lightgreen" }} />
          ) : (
            <Circle style={{ color: "red" }} />
          )}

          <KioskRowErrorLink
            syncErrorRef={row.syncErrorRef}
            locationCode={row.locationCode}
          />
        </div>
      </TableCell>

      {/* Name */}
      <TableCell component="th" scope="row">
        {row.locationName}
      </TableCell>

      {/* Collector Code */}
      <TableCell component="th" scope="row">
        {row.locationCode}
      </TableCell>

      {/* Kiosk Key */}
      <TableCell
        align="left"
        style={row.active ? {} : { textDecoration: "line-through" }}
      >
        {row.subscriptionID}
      </TableCell>

      {/* Address */}
      <TableCell align="left">{row.locationAddress}</TableCell>

      {/* <TableCell align="center">{row.counter}</TableCell> */}

      {/* Last Updated */}
      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
        {formatDate(row.lastSynced, "YYYY-MMM-DD HH:mm")} <br />
        {row.LastUpdatedBy ? (
          <span style={{ fontStyle: "italic" }}>{row.LastUpdatedBy}</span>
        ) : (
          <></>
        )}
      </TableCell>

      {/* Default Modes */}
      <TableCell align="left">
        {row.defaultWorkflowMode
          ? getFrontendString(row.defaultWorkflowMode)
          : "Bag and Labels"}
      </TableCell>

      {/* Modes */}
      <TableCell align="left">
        <KioskRowSessionWorkflows row={row} />
      </TableCell>

      {/* Action button */}
      <TableCell align="center">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title={"Edit"} arrow placement="top">
            <Button
              variant="outlined"
              onClick={async () => {
                handleActionEdit(row._id);
              }}
            >
              <EditIcon
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          </Tooltip>

          <Tooltip title={"Sync"} arrow placement="top">
            <Button
              variant="outlined"
              onClick={async () => {
                handleActionCollectorSync({
                  id: row._id,
                  locationCode: row.locationCode
                });
              }}
              style={{ marginLeft: "2px" }}
            >
              <SyncIcon
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Button>
          </Tooltip>

          <Tooltip title={"View Devices"} arrow placement="top">
            <Badge
              badgeContent={row && row.kioskKeys ? row.kioskKeys.length : ""}
              color="primary"
            >
              <Button
                variant="outlined"
                onClick={async () => {
                  handleActionViewKiosks(row._id);
                }}
                style={{ marginLeft: "2px" }}
              >
                <VideoLabelIcon
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                />
              </Button>
            </Badge>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default KioskRow;
