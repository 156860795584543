import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Dlog } from "./../../../../utils/Dlog";

import HttpContext from "../../../../contexts/HTTP/HttpContext";
import KioskContext from "../../../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
import QRCode from "react-qr-code";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import IdleTimerDisplay from "./../Shared/IdleTimerDisplay_V";
import LanguageButtons from "./../Shared/LanguageButtons";
import PhoneKeypad from "./../CustomerPhoneNumber/PhoneKeypad";
import PhoneConfirm from "./../CustomerPhoneNumber/PhoneConfirm";
import PhoneDisplay from "./../CustomerPhoneNumber/PhoneDisplay_V";
import BackspaceButton from "./../CustomerPhoneNumber/BackspaceButton";
import CircleButton from "./../Buttons/CircleButton_V";

import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import KioskStepper from "./../../Components/Stepper/KioskStepper_V";
import Logo from "./../../Components/Logo_V";

import BiRowNumberSelect from "./../Shared/NumberOf/BiRowNumberSelect";

import formatFirstNameLastInitial from "./../../HelperFunctions/formatFirstNameLastInitial";

import { useTheme } from "@mui/material/styles";

let env = require("./../../../../env");

function displayError(errorCode) {
  switch (errorCode) {
    case 400:
      return translate("application.phonenumber.errors.unablefetchaccount");
    case 401:
      return translate("application.phonenumber.errors.invalidphone");
    case 404:
      return translate("application.errors.invalidcredentials");
    case 503:
      return translate("application.errors.serverunavailable");
    default:
      return translate("application.errors.unknownerror");
  }
}

export default function VerticalLayout(props) {
  const {
    setKioskkeyFunc,
    nextFunc,
    loading,
    //errorMessage,
    isNotMobile,
    kioskkey,
    numbagpress,
    workflowmode,
    getKioskConfiguration
  } = props;

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const { simplepost } = useContext(HttpContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const [phonenumber, setPhonenumber] = useState("");
  const [showconfirmdialog, setShowconfirmdialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [workflowMode, setWorkflowMode] = useState(
    getKioskConfiguration().workflowMode
  );

  const keypadDone = async () => {
    console.log("next called");

    //Attempt Kiosk Login
    const res = await simplepost("/kiosk/getCustomer", {
      type: "phone",
      value: phonenumber
    });

    //console.log("CPN DEBUG res.data", res.data);

    //var customerName = res.data.name;
    var customerFirstName = res.data.firstName;
    var customerLastName = res.data.lastName;
    var customerID = res.data.uniqueID;

    if (res.status >= 400) {
      setErrorMessage(displayError(res.status));
      return;
    }

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      null, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    setErrorMessage("");
    setShowconfirmdialog(true);
  };

  // const hideConfirmDialog = async () => {
  //   setPhonenumber("");
  //   setShowconfirmdialog(false);
  // };

  // const displaystyleForconfirmdialog = async () => {
  //   Dlog("displaystyleForconfirmdialog", showconfirmdialog);

  //   if (showconfirmdialog) {
  //     return { display: "none" };
  //   } else {
  //     return { display: "block" };
  //   }
  // };

  // const backspacepress = async () => {
  //   let newNumber = phonenumber;

  //   if (newNumber.length > 0) {
  //     newNumber = newNumber.substring(0, newNumber.length - 1);
  //   }

  //   setPhonenumber(newNumber);
  // };

  // const next = async () => {
  //   navigate("/numbags");
  // };

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  const locationAddress = localStorage.getItem("LocationAddress");
  const locationName = localStorage.getItem("LocationName");

  return (
    <IdleTimerProvider timeout={30000} onIdle={onIdle}>
      <div style={kioskverticalstyles_2.flexBackground}>
        <div style={kioskverticalstyles_2.logo}>
          <center>
            <Logo />
          </center>
        </div>

        <div style={kioskverticalstyles_2.instructionDiv}>
          <div style={kioskverticalstyles_2.instructionVerticallyAlignDiv}>
            <Translate
              value="application.welcome"
              name={formatFirstNameLastInitial(
                customerFirstName,
                customerLastName
              )}
            />
            <br />
            <Translate
              value="application.kioskdetail.youareatV"
              name={locationName}
              address={locationAddress}
            />
          </div>
        </div>

        <div
          style={{
            ...kioskverticalstyles_2.whiteDiv,
            ...kioskverticalstyles_2.flexcenterDiv
            //justifyContent: "space-between"    //Not using to keep layout save with other NumberOf
          }}
        >
          <div
            style={{
              marginTop: "5vh",
              height: "7.7vh", //Not commenting out to keep layout save with other NumberOf
              marginBottom: "7.7vh", //Not commenting out to keep layout save with other NumberOf
              width: "83vw"
            }}
          >
            <div
              class="fontSecondaryText"
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "3.4vh"
              }}
            >
              <Translate value="application.numberofbags.selectnumbags" />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2vw",
              width: "100%",
              marginBottom: "8.2vh"
              //marginBottom: "0.5vh"    //Not using to keep layout save with other NumberOf
            }}
          >
            <BiRowNumberSelect
              workflowmode={workflowMode}
              rowstyle={kioskverticalstyles_2.biRowNumberSelect}
              CircleButtonElement={CircleButton}
              numbagpressFunc={numbagpress}
            />
          </div>
          <div
            class="fontSecondaryText"
            style={{
              // ...kioskstyles.leftRightMargins,
              // ...kioskstyles.blackBodyText,
              display: "flex",
              flexDirection: "column",
              width: "83vw",
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: "0.6vh",
              paddingRight: "0.6vh"
            }}
          >
            <div style={kioskverticalstyles_2.InfoText}>
              <div
                style={{
                  marginBottom: "2vh",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <span>
                  <b>
                    {workflowmode == "bag" ? (
                      <Translate
                        value="application.numberofbags.havemorebags"
                        count="8"
                      />
                    ) : (
                      <Translate
                        value="application.numberofbags.havemorebags"
                        count="7"
                      />
                    )}
                  </b>
                </span>{" "}
                <span>
                  <Translate value="application.numberofbags.multipleinteractions" />
                </span>
              </div>
              <div
                style={{
                  //marginBottom: "3vh",    //Not using to keep layout save with other NumberOf
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                {workflowmode == "bag" ? (
                  <></>
                ) : (
                  <>
                    <span>
                      <b>
                        <Translate value="application.numberofbags.needonlybags" />
                      </b>
                    </span>{" "}
                    <span>
                      <Translate value="application.numberofbags.selectzerobags" />
                    </span>
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={kioskverticalstyles_2.footerDiv}>
          <KioskStepper
            activeStep={1}
            backUrl={"/phonenumber"}
            workflowmode={workflowmode}
            getKioskConfiguration={getKioskConfiguration}
          />
        </div>
      </div>
      <IdleTimerDisplay />
    </IdleTimerProvider>
  );
}
