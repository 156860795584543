import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Grid,
  Autocomplete
} from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { useTheme } from "@mui/material/styles";

export default function KioskEditDialog({
  subscription,
  open,
  handleClose,
  handleSaveEditChanges,
  newlocationOptions,
  newlocationList,
  locationsLoading
}) {
  const theme = useTheme();

  const [subscriptions, setSubscriptions] = useState(subscription);

  const [locationName, setLocationName] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [active, setActive] = useState(true);

  useEffect(() => {
    setSubscriptions(subscription);

    if (subscription) {
      setLocationName(subscription.locationName);
      setLocationAddress(subscription.locationAddress);
      setLocationCode(subscription.locationCode);
      setSubscriptionID(subscription.subscriptionID);
      setActive(subscription.active);
    }
  }, [subscription]);

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (locationCode !== null && subscription !== null) {
      setLocationList([...newlocationList, subscription.locationCode]);
    }
  }, [newlocationList, subscription]);

  const changeLocationCombo = async (evt, newlocation) => {
    setLocationCode(newlocation);

    //Find info from locationOptions
    //let locationOption = locationOptions.find(l => {
    //  return l.code == newlocation;
    //});
    //console.log("changeLocationCombo locationOption", locationOption);
    //setLocationobject(locationOption);
  };

  const saveChanges = async () => {
    const payload = {
      _id: subscription._id,
      locationName: locationName,
      locationAddress: locationAddress,
      locationCode: locationCode,
      subscriptionID: subscriptionID,
      active: active
    };

    await handleSaveEditChanges(payload);
    //handleClose(); //Moved to handleSaveEditChanges
  };

  const revertChanges = async () => {
    if (subscription) {
      setLocationName(subscription.locationName);
      setLocationAddress(subscription.locationAddress);
      setLocationCode(subscription.locationCode);
      setSubscriptionID(subscription.subscriptionID);
      setActive(subscription.active);
    }

    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={false}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-kiosklist"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "30vw"
          }}
        >
          <DialogTitle
            id="modal-kiosklist"
            style={{
              maxWidth: "19vw"
            }}
          >
            Edit {subscriptions ? subscriptions.locationName : ""} [
            {subscriptions ? subscriptions.locationCode : ""}]
          </DialogTitle>
          <FormControlLabel
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: theme.spacing(3)
            }}
            control={
              <Switch
                checked={active}
                color="success"
                onChange={event => {
                  setActive(event.target.checked);
                }}
              />
            }
            label={active ? "Active" : "Disabled"}
          />
        </div>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container width="30vw" spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Location Name"
                value={locationName}
                onChange={event => {
                  let newText = event.target.value;
                  setLocationName(newText);
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                id="cblocationoptions"
                options={locationList ? locationList : []}
                onChange={changeLocationCombo}
                value={locationCode}
                getOptionLabel={option => {
                  return option ? option || option.toString() : "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return option && value
                    ? option.id === value.id
                    : option === value;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Location Code"
                    value={locationCode}
                    style={{ marginBottom: theme.spacing(2) }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="Location Address"
                value={locationAddress}
                onChange={event => {
                  let newText = event.target.value;
                  setLocationAddress(newText);
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Authenication Key"
                value={subscriptionID}
                onChange={event => {
                  let newText = event.target.value;
                  setSubscriptionID(newText);
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          {/* </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={saveChanges} color="secondary">
            Save
          </Button>
          <Button onClick={revertChanges}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
