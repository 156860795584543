import React, { useContext, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFormik } from "formik";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  Paper
} from "@mui/material";

import EnvironmentDisplay from "../../Kiosk/Components/Logo/EnvironmentDisplay";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import HttpContext from "../../../contexts/HTTP/HttpContext";

let env = require("../../../env");

const ERRORS = {
  400: "An error occurred while processing your request",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function ForgetPassword({ authenticate }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: async values => {
      setLoading(true);

      const res = await post("/forgetpassword", values);
      if (res.status >= 400) {
        setLoginError(ERRORS[res.status]);
      } else {
        //DIrect to Sent page
        navigate("/forgetpasswordsent");
        setLoginError("");
      }

      setLoading(false);
    }
  });

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "lightgrey" //Should be consignee Green
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //height: "20vh",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2vw"
        }}
      >
        <EnvironmentDisplay />
        <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
          Consignaction Kiosk
        </Typography>{" "}
      </div>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "4vw"
        }}
      >
        <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
          Forgot Password
        </Typography>

        <TextField
          fullWidth
          style={{ margin: theme.spacing(0.75) }}
          variant="outlined"
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled={loading}
          onKeyDown={handleKeyDown}
        />
        <Typography
          variant="body2"
          style={{ color: "red", marginTop: theme.spacing(0.5) }}
        >
          {loginError}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          onClick={formik.handleSubmit}
          style={{ marginTop: theme.spacing(2) }}
        >
          Submit
        </Button>
      </Paper>
      <span
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2vw",
          color: "grey"
        }}
      >
        {env.REACT_APP_VERSION}
      </span>
    </div>
  );
}

export default ForgetPassword;
