var Readable = {
  bag: "Bags Only",
  baglabel: "Bag and Labels"
};

export const getFrontendString = databaseString => {
  if (Readable[databaseString]) {
    return Readable[databaseString];
  } else return databaseString;
};
