import * as React from "react";

import ClearIcon from "@mui/icons-material/Clear";

import { Button } from "@mui/material";

function KioskRowClearErrorButton({
  syncErrorRef,
  id,
  handleActionClearError
}) {
  if (syncErrorRef === undefined || syncErrorRef === null) {
    return <></>;
  } else {
    return (
      <Button
        variant="outlined"
        onClick={async () => {
          console.log("calling handleActionClearError with id", id);
          handleActionClearError(id);
        }}
      >
        <ClearIcon id="fade-button" />
      </Button>
    );
  }
}

export default KioskRowClearErrorButton;
