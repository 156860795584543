import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "./../../../hooks/useSnackbar";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Collapse
} from "@mui/material";

import FormControl from "@mui/material/FormControl";

import { useTheme } from "@mui/material/styles";

import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerLoading from "../../../components/Loading/ContainerLoading";
import MyNumberInput from "../../Admin/components/Shared/myNumberInput";

import { formatDate } from "../../../utils/misc";

import UpdateLogRow from "./ErrorLogs/ErrorLogRow";

import EditDialog from "./Configuration/EditDialog";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

import moment from "moment";

import { WORKFLOW_MODES } from "../../../constants";

function ServerSettings() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [editDialogIsOpened, setEditDialogIsOpened] = useState(false);
  const [editDialogData, setEditDialogData] = useState(null);
  const [editDialogIndex, setEditDialogIndex] = useState(null);

  const [serverSetting, setServerSetting] = useState(null);

  const { openSnackbar } = useSnackbar();

  const loadData = async () => {
    setLoading(true);

    //Load data
    const res2 = await get("/kiosk/getServerSetting/ConsignAction");

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to load server settings",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }

    if (res2.status == 403 || res2.status == 401) {
      //logout and redirect to login
      await get("/logout");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }

    if (res2.status === 200) {
      const data = _.get(res2, "data");

      await setServerSetting(data);
    }

    setLoading(false);
  };

  const handleUpdateSettings = async settings => {
    let payload = { _id: serverSetting._id, settings: settings };

    console.log("payload", payload);

    const res2 = await post("/kiosk/serverSetting/ConsignAction", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //SInce format can change, just reload from server
      openSnackbar({
        open: true,
        message: "Successfully updated!",
        variant: "alert",
        alert: {
          color: "success"
        },
        close: false
      });
      await loadData();

      return subscriptionData;
    }
  };

  const EditSettings = index => {
    console.log("Configuration.EditSettings() index", index);
    setEditDialogIndex(index);
    setEditDialogData(setServerSetting);
    setEditDialogIsOpened(true);
  };

  const loadDetails = async id => {};

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">Server Settings</Typography>
          {/* <Typography>View and edit your server settings</Typography> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: theme.spacing(2)
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1)
          }}
        >
          <Button
            variant="contained"
            onClick={() => loadData()}
            disabled={loading}
          >
            <RefreshIcon style={{ marginRight: theme.spacing(1) }} />
            Refresh
          </Button>
        </div>
      </div>

      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead></TableHead>

          {serverSetting !== undefined && serverSetting !== null ? (
            <TableBody>
              {/* <TableRow>
                <TableCell align="left">Enable Web Client</TableCell>
                <TableCell align="left">
                  <Checkbox
                    size="small"
                    checked={serverSetting.settings.enabledWeb}
                  />
                </TableCell>
              </TableRow> */}

              <TableRow>
                <TableCell align="center">CAApp Hostname</TableCell>
                <TableCell align="center">
                  {/* //Batch Update */}
                  <FormControl sx={{ m: 1, minWidth: 230 }}>
                    <TextField
                      id="txtcaapphostname"
                      label="Hostname"
                      variant="outlined"
                      value={serverSetting.settings.consignactionappHostname}
                      onChange={async event => {
                        handleUpdateSettings({
                          ...serverSetting.settings,
                          consignactionappHostname: event.target.value
                        });
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">Default New Kiosk Workflow</TableCell>
                <TableCell align="center">
                  {/* //Batch Update */}
                  <FormControl sx={{ m: 1, minWidth: 230 }}>
                    <Select
                      labelId="batch-update-open-select-label"
                      id="batch-update-open-select"
                      //label="Workflow Mode"
                      value={serverSetting.settings.defaultNewKioskWorkflow}
                      onChange={async event => {
                        handleUpdateSettings({
                          ...serverSetting.settings,
                          defaultNewKioskWorkflow: event.target.value
                        });
                      }}
                    >
                      <MenuItem value={WORKFLOW_MODES.BAGS_AND_LABELS}>
                        Bag and Labels
                      </MenuItem>
                      <MenuItem value={WORKFLOW_MODES.BAG_ONLY}>
                        Bags Only
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">Enable Error Logs</TableCell>
                <TableCell align="center">
                  {/* //Batch Update */}
                  <Switch
                    checked={serverSetting.settings.syncLoggingEnabled}
                    onChange={async event => {
                      console.log("Toggle onChange event", event);
                      handleUpdateSettings({
                        ...serverSetting.settings,
                        syncLoggingEnabled: event.target.checked
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
              </TableRow>

              {serverSetting.settings.syncLoggingEnabled ? (
                <>
                  <TableRow>
                    <TableCell align="center">
                      Enable Inbound Payload Capture
                    </TableCell>
                    <TableCell align="center">
                      {/* //Batch Update */}
                      <Switch
                        checked={
                          serverSetting.settings.syncLoggingLogInboundData
                        }
                        onChange={async event => {
                          console.log("Toggle onChange event", event);
                          handleUpdateSettings({
                            ...serverSetting.settings,
                            syncLoggingLogInboundData: event.target.checked
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      Enable Automatic Log Cleanup
                    </TableCell>
                    <TableCell align="center">
                      {/* //Batch Update */}
                      <Switch
                        checked={serverSetting.settings.syncLoggingCullEnabled}
                        onChange={async event => {
                          console.log("Toggle onChange event", event);
                          handleUpdateSettings({
                            ...serverSetting.settings,
                            syncLoggingCullEnabled: event.target.checked
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <></>
              )}

              {!(
                serverSetting.settings.syncLoggingEnabled &&
                serverSetting.settings.syncLoggingCullEnabled
              ) ? (
                <></>
              ) : (
                <>
                  <TableRow>
                    <TableCell align="center">Log Cleanup Threshold</TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ m: 1, minWidth: 230 }}>
                        <MyNumberInput
                          id="outlined-number"
                          value={serverSetting.settings.syncLoggingCullTrigger}
                          onBlur={async val => {
                            await handleUpdateSettings({
                              ...serverSetting.settings,
                              syncLoggingCullTrigger: val
                            });
                          }}
                          checkValueHandler={value => {
                            if (value > 39) {
                              return true;
                            }
                            openSnackbar({
                              open: true,
                              message: "Value must be at least 40",
                              variant: "alert",
                              alert: {
                                color: "error"
                              },
                              close: false
                            });
                            return false;
                          }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">
                      Logs to Retain After Cleanup
                    </TableCell>
                    <TableCell align="center">
                      <FormControl sx={{ m: 1, minWidth: 230 }}>
                        <MyNumberInput
                          id="outlined-number"
                          value={
                            serverSetting.settings.syncLoggingCullKeepTotal
                          }
                          onBlur={async val => {
                            await handleUpdateSettings({
                              ...serverSetting.settings,
                              syncLoggingCullKeepTotal: val
                            });
                          }}
                          checkValueHandler={value => {
                            if (
                              value > 39 &&
                              value <
                                serverSetting.settings.syncLoggingCullTrigger
                            ) {
                              return true;
                            }
                            openSnackbar({
                              open: true,
                              message:
                                "Value must be at least 40 and less than Cull Trigger amount",
                              variant: "alert",
                              alert: {
                                color: "error"
                              },
                              close: false
                            });
                            return false;
                          }}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          ) : (
            <TableBody>Loading</TableBody>
          )}
          {/* ))} */}
        </Table>
      </TableContainer>
      <EditDialog
        open={editDialogIsOpened}
        editDialogData={editDialogData}
        editDialogIndex={editDialogIndex}
        handleClose={() => {
          setEditDialogIsOpened(false);
        }}
      />
    </Grid>
  );
}

export default ServerSettings;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
