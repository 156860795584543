import React, { useContext, useState, useEffect } from "react";

export default function BiRowNumberSelect({
  workflowmode,
  rowstyle,
  CircleButtonElement,
  numbagpressFunc
}) {
  if (workflowmode == "bag") {
    return (
      <>
        <div key={"bagsButRow1"} style={rowstyle}>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            1
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            2
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            3
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            4
          </CircleButtonElement>
        </div>
        <div key={"bagsButRow2"} style={rowstyle}>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            5
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            6
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            7
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            8
          </CircleButtonElement>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div key={"bagsButRow1"} style={rowstyle}>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            0
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            1
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            2
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            3
          </CircleButtonElement>
        </div>
        <div key={"bagsButRow2"} style={rowstyle}>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            4
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            5
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            6
          </CircleButtonElement>
          <CircleButtonElement PercentSize="10%" onClick={numbagpressFunc}>
            7
          </CircleButtonElement>
        </div>
      </>
    );
  }
}
