import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "./../../../hooks/useSnackbar";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "./Shared/VisibilityIcon";
import SyncIcon from "@mui/icons-material/Sync";

import AlertDialog from "../../../components/Dialogs/AlertDialog";
import DetailsDialog from "./Kiosks/DetailsDialog";
import ContainerLoading from "../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../utils/misc";

import KioskRow from "./Kiosks/KioskRow";
import CreateKioskGroupDialog from "./Kiosks/CreateKioskGroupDialog";
import KioskListDialog from "./Kiosks/KiosksListDialog";
import KioskSetAllWorkflowDialog from "./Kiosks/KiosksSetAllWorkflowDialog";
import KioskEditDialog from "./Kiosks/KioskEditDialog";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

function Kiosks() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [showCreateKeyDialog, setShowCreateKeyDialog] = useState(false);
  const [createKeyName, setCreateKeyName] = useState("");

  const [showKeyDialog, setShowKeyDialog] = useState(false);
  const [keyForTempDisplay, setKeyForTempDisplay] = useState("");

  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const [showKiosksListDialog, setShowKiosksListDialog] = useState(false);
  const [kiosklistKiosk, setKiosklistKiosk] = useState(null);

  const [showKioskEditDialog, setShowKioskEditDialog] = useState(false);
  const [kioskEditTarget, setKioskEditTarget] = useState(null);
  const [kioskEditKiosk, setKioskEditKiosk] = useState(null);

  const [showSetAllWorkflowDialog, setShowSetAllWorkflowDialog] = useState(
    false
  );

  const [showFullSyncDialog, setShowFullSyncDialog] = useState(false);
  const [showCollectorSyncDialog, setShowCollectorSyncDialog] = useState(false);
  const [collectorSyncTarget, setCollectorSyncTarget] = useState({
    id: null,
    locationCode: null
  });

  const [allWorkflowSubscription, setAllWorkflowSubscription] = useState(null);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null);

  const [showDisabledEntries, setShowDisabledEntries] = useState(false);

  const [showDeleteKeyDialog, setShowDeleteKeyDialog] = useState(false);
  const [keyObjToDelete, setKeyObjToDelete] = useState(null);

  const [allKeys, setAllKeys] = useState([]);
  const [numHiddenKeys, setNumHiddenKeys] = useState(0);

  const [currentSubscriptionData, setCurrentSubscriptionData] = useState(null);

  const { openSnackbar } = useSnackbar();

  const loadKeys = async () => {
    setLoading(true);

    const res2 = await get("/kiosk/getAllSubscription");

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to load kiosk data",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }

    if (res2.status == 403 || res2.status == 401) {
      //logout and redirect to login
      await get("/logout");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }

    if (res2.status === 200) {
      const kioskGroups = _.get(res2, "data");
      setAllKeys(kioskGroups || []);
      if (kioskGroups) {
        setNumHiddenKeys(kioskGroups.filter(kg => kg.active == false).length);
      }

      //console.log(kioskGroups);
    }

    setLoading(false);
  };

  const generateAPIKey = async () => {
    setLoading(true);

    setCreateKeyName("");
    setShowCreateKeyDialog(false);

    const res = await get("/generateAPIKey", { name: createKeyName });
    if (res.status === 200) {
      const key = _.get(res, "data.key");
      if (key) {
        setKeyForTempDisplay(key);
        setShowKeyDialog(true);
      }
    }

    await loadKeys();

    setLoading(false);
  };

  const handleDeleteKey = async () => {
    if (!keyObjToDelete) return;

    setLoading(true);

    await get("/deleteAPIKey", { name: _.get(keyObjToDelete, "name") });
    await loadKeys();

    setKeyObjToDelete(null);
    setShowDeleteKeyDialog(false);

    setLoading(false);
  };

  const loadDetails = async id => {
    //Load details via id
    const res2 = await get("/kiosk/getSubscription/" + id);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");
      setCurrentSubscriptionData(subscriptionData);
      //console.log("loadDetails", subscriptionData);
    }
  };

  const handleActionViewKiosks = id => {
    //Find list of kiosk for id
    setKiosklistKiosk(allKeys.find(item => item._id === id));

    setCurrentSubscriptionId(id);
    setShowKiosksListDialog(true);
  };

  const handleActionEdit = id => {
    const kiosk = allKeys.find(item => item._id === id);
    setKioskEditKiosk(kiosk);

    setKioskEditTarget(id);

    //console.log("handleActionEdit kiosk id", kiosk, id);

    setShowKioskEditDialog(true);
  };

  const handleSaveEditChanges = async payload => {
    console.log("payload", payload);

    if (!payload) {
      openSnackbar({
        open: true,
        message: "Invalid payload.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      return;
    }

    if (payload.locationCode == null) {
      openSnackbar({
        open: true,
        message: "Invalid Location Code. Please select a valid location code.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      return;
    }

    const res2 = await post("/kiosk/editSubscription", payload);

    //console.log("res2.data", res2.data);

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to save changes.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      setShowKioskEditDialog(false);
      return;
    }

    openSnackbar({
      open: true,
      message: "Changes saved successfully!",
      variant: "alert",
      alert: {
        color: "success"
      },
      close: false
    });
    await loadKeys();
    setShowKioskEditDialog(false);
  };

  //////// Location Functions /////////

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(false);

  const getDropLocations = async () => {
    setLocationsLoading(true);
    const res = await get("/kiosk/getDropLocations");

    console.log("allKeys", allKeys);

    let usedCodes = allKeys.map(i => i.locationCode);
    console.log("usedCodes", usedCodes);

    if (res.status !== 200) {
      setLocationsLoading(false);
      openSnackbar({
        open: true,
        message: "Unable to load Drop Locations.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      return;
    }

    if (res.status === 200) {
      console.log("GOT 200 from getDropLocations res.data", res.data);

      //TODO: Check format of data
      setLocationOptions(res.data.dropLocations);

      let ll = res.data.dropLocations.map(location => location.code);
      console.log("ll", ll);

      let filteredCodes = ll.filter(i => !usedCodes.includes(i));
      console.log("filteredCodes", filteredCodes);

      //setLocationList(ll);
      setLocationList(filteredCodes);

      setLocationsLoading(false);
      openSnackbar({
        open: true,
        message: "Drop Locations Updated!",
        variant: "alert",
        alert: {
          color: "success"
        },
        close: false
      });
    }
  };

  //////// Workflow Functions /////////

  const handleSetWorkflowModeKiosk = async (
    subscriptionID,
    kioskID,
    newWorkflowMode
  ) => {
    let payload = {
      subscriptionID: subscriptionID,
      kioskID: kioskID,
      newWorkflowMode: newWorkflowMode
    };

    const res2 = await post("/kiosk/updateWorkflow", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //SInce format can change, just reload from server
      await loadKeys();

      //Either update data or repull from server

      //Update modal
      await loadDetails(currentSubscriptionId);

      return subscriptionData;
    }
  };

  const handleSetWorkflowModeSubscription = async (
    subscriptionID,
    newWorkflowMode
  ) => {
    let payload = {
      subscriptionID: subscriptionID,
      newWorkflowMode: newWorkflowMode
    };

    const res2 = await post("/kiosk/updateAllWorkflow", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //SInce format can change, just reload from server
      await loadKeys();

      //Since we moved the dialog inside the display List, the following will update the list
      setKiosklistKiosk(
        allKeys.find(item => item._id === currentSubscriptionId)
      );

      //setShowSetAllWorkflowDialog(false);
      //Either update data or repull from server
      return subscriptionData;
    }
  };

  const handleSetDefaultWorkflowModeSubscription = async (
    subscriptionID,
    newWorkflowMode
  ) => {
    let payload = {
      subscriptionID: subscriptionID,
      defaultWorkflowMode: newWorkflowMode
    };

    const res2 = await post("/kiosk/updateDefaultWorkflow", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //Since format can change, just reload from server
      await loadKeys();

      //Since we moved the dialog inside the display List, the following will update the list
      setKiosklistKiosk(
        allKeys.find(item => item._id === currentSubscriptionId)
      );

      //Either update data or repull from server
      return subscriptionData;
    }
  };

  const handleActionSetMode = id => {
    setAllWorkflowSubscription(allKeys.find(item => item._id === id));
    setShowSetAllWorkflowDialog(true);
  };

  const handleActionClearError = async id => {
    let payload = {
      id: id
    };

    //console.log("handleActionClearError payload id", payload, id);

    const res2 = await post("/kiosk/clearSubscriptionError/", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //Since format can change, just reload from server
      await loadKeys();
    }
  };

  //////// Sync Functions /////////

  const handleActionCollectorSync = async id => {
    setCollectorSyncTarget(id);
    setShowCollectorSyncDialog(true);
  };

  const performActionCollectorSync = async id => {
    let payload = {
      id: collectorSyncTarget.id,
      code: collectorSyncTarget.locationCode
    };

    //console.log("performActionCollectorSync payload id", payload, id);

    const res2 = await post("/kiosk/syncTechnology/", payload);

    //console.log("performActionCollectorSync res2", res2);

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to save changes.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      setShowCollectorSyncDialog(false);
      return;
    }

    openSnackbar({
      open: true,
      message: "Changes saved successfully!",
      variant: "alert",
      alert: {
        color: "success"
      },
      close: false
    });

    setShowCollectorSyncDialog(false);
    await loadKeys();
  };

  const performActionGlobalCollectorSync = async () => {
    //console.log("performActionGlobalCollectorSync");

    setShowFullSyncDialog(false);
    setLoading(true); //loadKeys will set loading to false;
    const res2 = await post("/kiosk/syncAllTechnologies/");

    //console.log("performActionGlobalCollectorSync res2", res2);

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to save changes.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      setLoading(false);
      return;
    }

    openSnackbar({
      open: true,
      message: "Changes saved successfully!",
      variant: "alert",
      alert: {
        color: "success"
      },
      close: false
    });

    await loadKeys();
  };

  useEffect(() => {
    (async () => {
      //await getDropLocations();
      await loadKeys();
    })();
  }, []);

  //So that we can filter out used dropLocations
  useEffect(() => {
    (async () => {
      await getDropLocations();
    })();
  }, [allKeys]);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: theme.spacing(2)
          }}
        >
          <Typography variant="h4">Kiosks</Typography>
          <Typography variant="h5" style={{ marginLeft: theme.spacing(2) }}>
            <span style={{ color: "grey" }}>
              ({allKeys.length} result{allKeys.length == 1 ? "" : "s"}
              {!showDisabledEntries && numHiddenKeys > 0
                ? ", " + numHiddenKeys + " hidden"
                : ""}
              )
            </span>
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: theme.spacing(2)
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1)
          }}
        >
          <Button
            variant="outline"
            onClick={() => {
              setShowDisabledEntries(!showDisabledEntries);
              //console.log(showDisabledEntries);
            }}
            disabled={loading}
          >
            <VisibilityIcon
              visible={showDisabledEntries}
              style={{ marginRight: theme.spacing(1) }}
            />
            {showDisabledEntries ? "Hide" : "Include"} Disabled
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowCreateKeyDialog(true)}
            disabled={loading}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Add Kiosk Location
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setShowFullSyncDialog(true)}
            disabled={loading}
          >
            <SyncIcon style={{ marginRight: theme.spacing(1) }} />
            Full Sync
          </Button>
        </div>
        <div>
          {/* <Button
            variant="outline"
            onClick={() => {
              setShowDisabledEntries(!showDisabledEntries);
              console.log(showDisabledEntries);
            }}
            disabled={loading}
          >
            <VisibilityIcon
              visible={showDisabledEntries}
              style={{ marginRight: theme.spacing(1) }}
            />
            {showDisabledEntries ? "Hide" : "Include"} Disabled
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowCreateKeyDialog(true)}
            disabled={loading}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Add Kiosk Location
          </Button> */}
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Collector Code</TableCell>
              <TableCell align="left">Kiosk Key</TableCell>

              <TableCell align="left">Address</TableCell>

              <TableCell align="left">Last Updated</TableCell>

              <TableCell align="left">Default Mode</TableCell>
              <TableCell align="left">Modes</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allKeys.map(row => (
              <KioskRow
                row={row}
                showDisabledEntries={showDisabledEntries}
                handleActionViewKiosks={handleActionViewKiosks}
                handleActionSetMode={handleActionSetMode}
                handleActionClearError={handleActionClearError}
                handleActionCollectorSync={handleActionCollectorSync}
                handleActionEdit={handleActionEdit}
                // setShowDetailsDialog={setShowDetailsDialog}
                // loadDetails={loadDetails}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        open={showKeyDialog}
        handleClose={() => {
          setShowKeyDialog(false);
          setKeyForTempDisplay("");
        }}
        title="Your API Key"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Here is your API key:
        </Typography>
        <Paper elevation={3} sx={{ p: 2, marginBottom: theme.spacing(2) }}>
          <Typography
            variant="body2"
            component="code"
            style={{ wordWrap: "break-word" }}
          >
            {keyForTempDisplay}
          </Typography>
        </Paper>
        <Typography style={{ color: "red" }}>
          WARNING: Once you close this window, you will not be able to see this
          API key again. Make sure you note it down and keep it in a safe place.
        </Typography>
      </AlertDialog>
      <CreateKioskGroupDialog
        open={showCreateKeyDialog}
        handleConfirm={generateAPIKey}
        handleClose={() => setShowCreateKeyDialog(false)}
        disableConfirm={
          !isKeyNameValid(createKeyName) ||
          isKeyNameDuplicate(allKeys, createKeyName)
        }
        value={createKeyName}
        onChange={e => setCreateKeyName(e.target.value)}
        error={
          !isKeyNameValid(createKeyName) ||
          isKeyNameDuplicate(allKeys, createKeyName)
        }
        helperText={getKeyNameHelperText(allKeys, createKeyName)}
        loadKeys={loadKeys}
        setLoading={setLoading}
        locationOptions={locationOptions}
        locationList={locationList}
        locationsLoading={locationsLoading}
        locationsRefreshFunc={getDropLocations}
      />
      <KioskListDialog
        subscription={kiosklistKiosk}
        open={showKiosksListDialog}
        handleSetWorkflowModeKiosk={handleSetWorkflowModeKiosk}
        handleSetWorkflowModeSubscription={handleSetWorkflowModeSubscription}
        handleSetDefaultWorkflowModeSubscription={
          handleSetDefaultWorkflowModeSubscription
        }
        handleClose={() => setShowKiosksListDialog(false)}
      />
      <DetailsDialog
        open={showDetailsDialog}
        subscriptionData={currentSubscriptionData}
        handleClose={() => setShowDetailsDialog(false)}
      ></DetailsDialog>
      <AlertDialog
        open={showDeleteKeyDialog}
        handleConfirm={handleDeleteKey}
        handleClose={() => setShowDeleteKeyDialog(false)}
        title="Delete your API key"
        confirmButtonName="Confirm"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Are you sure you would like to delete the API key{" "}
          <b>{_.get(keyObjToDelete, "name", "")}</b>?
        </Typography>
        <Typography style={{ marginBottom: theme.spacing(2), color: "red" }}>
          WARNING: This key will no longer be usable.
        </Typography>
      </AlertDialog>

      <AlertDialog
        open={showFullSyncDialog}
        handleConfirm={performActionGlobalCollectorSync}
        handleClose={() => setShowFullSyncDialog(false)}
        title="Full Sync"
        confirmButtonName="Confirm"
        closeButtonName="Cancel"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Are you sure you would like to perform a full sync on all the
          collectors?
        </Typography>
      </AlertDialog>
      <AlertDialog
        open={showCollectorSyncDialog}
        handleConfirm={performActionCollectorSync}
        handleClose={() => setShowCollectorSyncDialog(false)}
        title="Collector Sync"
        confirmButtonName="Confirm"
        closeButtonName="Cancel"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          Are you sure you would like to perform a sync on{" "}
          {collectorSyncTarget.locationCode
            ? collectorSyncTarget.locationCode
            : ""}
          ?
        </Typography>
      </AlertDialog>
      <KioskEditDialog
        subscription={kioskEditKiosk}
        open={showKioskEditDialog}
        handleSaveEditChanges={handleSaveEditChanges}
        handleClose={() => setShowKioskEditDialog(false)}
        newlocationOptions={locationOptions}
        newlocationList={locationList}
        locationsLoading={locationsLoading}
        //locationsRefreshFunc={getDropLocations}
      />
    </Grid>
  );
}

export default Kiosks;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
