import React, { useContext, useState, useRef, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";
import { useReactToPrint } from "react-to-print";

import { kioskstyles } from "./../../kioskstyles";
import KioskStylesReact from "./../../KioskStylesReact";

import { LabelsToPrint } from "./Components/LabelsToPrint";

import { Capacitor } from "@capacitor/core";
import EpsonPrinter from "./../../plugin/EsponPrinter";

import VerticalLayout from "./Components/PrintLabels/VerticalLayout";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";
import SocketIOContext from "../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

let env = require("./../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { post } = useContext(HttpContext);
  const { getKioskConfiguration } = useContext(SocketIOContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const {
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    labelsList,
    setAll,
    customerReset
  } = useContext(KioskContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  const [orientation, setOrientation] = useState(
    window.screen.orientation.type
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  useEffect(() => {
    next();
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const next = async () => {
    Dlog("next called");

    Dlog(
      "DEBUG context Dump 5",
      customerFirstName,
      customerLastName,
      customerID,
      numBags,
      numLabels
    );

    const androidEsponPrinter = Capacitor.isPluginAvailable("EpsonPrinter");
    if (androidEsponPrinter) {
      //let value = EpsonPrinter.echo({ value: "Hello World!" });
      try {
        let value = await EpsonPrinter.PrintWithSelectedPrinter({
          bagtags: labelsList
        });
        Dlog("Response from native:", value);
      } catch (e) {
        console.log("CATCHERROR: " + e.toString());
      }
    } else {
      console.log("EpsonPrinter plugin unvavailable. Probably not android");
      handlePrint();
    }

    navigate("/thanksyou");
  };

  Dlog("PrintLabel", customerFirstName, customerLastName, labelsList);

  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isLandscape = useMediaQuery("(orientation:landscape)");

  return (
    <div>
      <VerticalLayout getKioskConfiguration={getKioskConfiguration} />
      <LabelsToPrint
        ref={componentRef}
        customerName={formatFirstNameLastInitial(
          customerFirstName,
          customerLastName
        )}
        labelsList={labelsList}
      />
    </div>
  );
}

export default NumberOfBags;
