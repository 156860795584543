import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "./../../../hooks/useSnackbar";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TablePagination
} from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // or any other adapter like AdapterDateFns
import dayjs from "dayjs";

import { useTheme } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";

import ContainerLoading from "../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../utils/misc";

import BagtagLogRow from "./BagtagLogs/BagtagLogRow";
import LocationCodeDropdown from "./Shared/LocationCodeDropdown";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

let env = require("../../../env");
const APP_URL = env.REACT_SCOCKETIO_URL;

function BagtagLogs() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);

  const [filterBagtag, setFilterBagtag] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [filterKioskID, setFilterKioskID] = useState("");
  const [filterCustomerID, setFilterCustomerID] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(
    dayjs().subtract(14, "day")
  );
  const [filterEndDate, setFilterEndDate] = useState(dayjs());

  const [allLogs, setAllLogs] = useState([]);

  const { openSnackbar } = useSnackbar();

  //const [debugRelaspedTime, setDebugRelaspedTime] = useState("");

  const [paginationTotal, setPaginationTotal] = useState(0);
  const [paginationPage, setPaginationPage] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(25);
  const [paginationTotalPages, setPaginationTotalPages] = useState(0);

  const refresh = async () => {
    let payload = {
      bagtag: filterBagtag,
      locationCode: filterCode,
      kioskID: filterKioskID,
      customerID: filterCustomerID,
      startTime: filterStartDate.toISOString(),
      endTime: filterEndDate.toISOString(),
      page: paginationPage,
      limit: paginationLimit
      //startTime: "",
      //endTime: ""
    };

    refresh_core(payload);
  };

  const refresh_table = async (newpage, newlimit) => {
    let payload = {
      bagtag: filterBagtag,
      locationCode: filterCode,
      kioskID: filterKioskID,
      customerID: filterCustomerID,
      startTime: filterStartDate.toISOString(),
      endTime: filterEndDate.toISOString(),
      page: newpage,
      limit: newlimit
      //startTime: "",
      //endTime: ""
    };

    refresh_core(payload);
  };

  const refresh_core = async payload => {
    // Fetch and display socket info
    setLoading(true);

    console.log("payload", payload);

    let debugStart = new Date();
    const res = await post("/kiosk/getBagtag/", payload);
    let debugEnd = new Date();

    //var timeDiffms = debugEnd - debugStart; //in ms
    //setDebugRelaspedTime(timeDiffms);

    console.log("res", res);

    if (res.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to load bagtag logs",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }

    if (res.status == 403 || res.status == 401) {
      //logout and redirect to login
      await get("/logout");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }

    if (res.status == 200) {
      setAllLogs(res.data.data);

      if (res.data.metadata.length == 0) {
        setPaginationTotal(0);
        setPaginationPage(0);
        setPaginationTotalPages(0);
      } else {
        setPaginationTotal(res.data.metadata[0].total);
        setPaginationPage(res.data.metadata[0].page);
        setPaginationLimit(res.data.metadata[0].limit);
        setPaginationTotalPages(res.data.metadata[0].totalPages);
      }
    }

    setLoading(false);
  };

  const handleChangePage = async (event, newPage) => {
    await setPaginationPage(newPage);
    await refresh_table(newPage, paginationLimit);
  };

  const handleChangeRowsPerPage = async event => {
    const newlimit = parseInt(event.target.value, 10);
    await setPaginationLimit(newlimit);
    await setPaginationPage(0);
    await refresh_table(0, newlimit);
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: theme.spacing(2)
          }}
        >
          <Typography variant="h4">Bagtag Logs</Typography>
          <Typography variant="h5" style={{ marginLeft: theme.spacing(2) }}>
            <span style={{ color: "grey" }}>
              ({paginationTotal} result{paginationTotal == 1 ? "" : "s"})
              {/* {debugRelaspedTime} ms */}
            </span>
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: theme.spacing(2)
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1)
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Start Date"
              value={filterStartDate}
              onChange={newValue => {
                console.log("DateTimePicker", newValue);
                setFilterStartDate(newValue);
              }}
            />
            <DateTimePicker
              label="End Date"
              value={filterEndDate}
              onChange={newValue => {
                console.log("DateTimePicker", newValue);
                setFilterEndDate(newValue);
              }}
            />
          </LocalizationProvider>
          <LocationCodeDropdown
            value={filterCode}
            onChange={(e, val) => {
              setFilterCode(val);
            }}
          />
          <TextField
            id="bts"
            label="Bagtag"
            value={filterBagtag}
            onChange={event => {
              let newText = event.target.value;
              newText = newText.toUpperCase().trim();
              setFilterBagtag(newText);
            }}
          />
          <TextField
            id="kis"
            label="KioskID"
            value={filterKioskID}
            onChange={event => {
              let newText = event.target.value;
              newText = newText.trim();
              setFilterKioskID(newText);
            }}
          />
          <TextField
            id="cis"
            label="CustomerID"
            value={filterCustomerID}
            onChange={event => {
              let newText = event.target.value;
              newText = newText.trim();
              setFilterCustomerID(newText);
            }}
          />
        </div>
        <div>
          <Button variant="contained" onClick={() => refresh()}>
            <SearchIcon /> Search
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Bagtag</TableCell>
              <TableCell align="left">Timestamp</TableCell>
              <TableCell align="left">Location Code</TableCell>
              <TableCell align="left"># Bags</TableCell>
              <TableCell align="left"># Labels</TableCell>
              <TableCell align="left">Kiosk ID</TableCell>
              {/* <TableCell align="left">subscriptionID</TableCell> */}
              {/* <TableCell align="left">locationCode</TableCell> */}
              {/* <TableCell align="left">locationName</TableCell>
              <TableCell align="left">locationAddress</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {allLogs.map((row, index) => (
              <BagtagLogRow row={row} key={index} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={paginationTotal}
          page={paginationPage}
          onPageChange={handleChangePage}
          rowsPerPage={paginationLimit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Grid>
  );
}

export default BagtagLogs;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
