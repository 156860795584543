import React, { useContext, useEffect, useState } from "react";

import { TextField } from "@mui/material";

/**
 * Only updates onBlur, return to original value if invalid number onBlur.
 */
function MyNumberInput({ id, label, value, onBlur, checkValueHandler }) {
  const [numberValue, setNumberValue] = useState(value);
  const [tempNumberValue, setTempNumberValue] = useState(value);

  useEffect(() => {
    setNumberValue(value);
    setTempNumberValue(value);
  }, [value]);

  return (
    <TextField
      id="outlined-number"
      type="number"
      slotProps={{
        inputLabel: {
          shrink: true
        }
      }}
      value={tempNumberValue}
      onChange={async (event, val) => {
        setTempNumberValue(event.target.value);
      }}
      onBlur={async (event, val) => {
        if (checkValueHandler(tempNumberValue)) {
          setNumberValue(tempNumberValue);
          return onBlur(tempNumberValue);
        }
        setNumberValue(numberValue);
        setTempNumberValue(numberValue);
      }}
    />
  );
}

export default MyNumberInput;
