import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
import QRCode from "react-qr-code";

import { kioskstyles } from "./../../../../kioskstyles";
//import KioskStylesReact from "./../../../KioskStylesReact";
import KioskStylesReact from "./../../../../KioskStylesReact";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import CircleButton from "./../../Components/Buttons/CircleButton";
import Logo from "./../../Components/Logo";
import LanguageButtons from "./../../Components/Shared/LanguageButtons";
import SubtitleWrapper from "./../../Components/Shared/PanelElements/SubtitleWrapper";
import FlexLeftPanel from "./../../Components/Shared/PanelElements/FlexLeftPanel";
import FlexRightPanel from "./../../Components/Shared/PanelElements/FlexRightPanel";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../../../utils/Dlog";

import KioskIDButton from "./../Shared/KioskIDButton";

import HttpContext from "../../../../contexts/HTTP/HttpContext";
import KioskContext from "../../../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import SocketIOContext from "../../../../contexts/Kiosk/SocketProvider/SocketIOContext";

import SplashImage from "./../../Components/LanguageSelect/SplashImage";

//Components/LanguageSelect/SplashImage";

let env = require("./../../../../env");

// import React, { useContext, useState, useEffect } from "react";
// import _ from "lodash";
// import useMediaQuery from "@mui/material/useMediaQuery";

// import { Translate, Localize, translate } from "react-i18nify";
// import { Link } from "react-router-dom";
// import { useFormik } from "formik";
// import { setTranslations, setLocale } from "react-i18nify";
// //import QRCode from "react-qr-code";
// import { QRCode } from "react-qrcode-logo";

// import LanguageButtons from "./../Shared/LanguageButtons";

// import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";

// import {
//   Button,
//   Grid,
//   FormControl,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   TextField,
//   Typography,
//   OutlinedInput,
//   ButtonGroup
// } from "@mui/material";

// import Logo from "./../../Components/Logo";

// import { useTheme } from "@mui/material/styles";

// let env = require("./../../../../env");

export default function HorizontalLayout(props) {
  const {
    setKioskkeyFunc,
    nextFunc,
    loading,
    errorMessage,
    isNotMobile,
    kioskkey
  } = props;

  const {
    isConnected,
    socketEvents,
    qrCode,
    connect,
    disconnect,
    getSocket,
    sendEvent,
    clearSocketEvents
  } = useContext(SocketIOContext);

  const theme = useTheme();

  return (
    // <div style={kioskverticalstyles_2.languageselect.flexBackground}>
    //   <div style={kioskverticalstyles_2.languageselect.logo}>
    //     <center>
    //       <Logo />
    //     </center>
    //   </div>

    //   <div class="fontBold" style={kioskverticalstyles_2.languageselect.title}>
    //     <Translate value="application.languageselect.kioskname" />
    //   </div>

    //   <div style={kioskverticalstyles_2.languageselect.button}>
    //     <Button
    //       variant="outlined"
    //       fullWidth
    //       style={{
    //         backgroundColor: "white",
    //         color: "rgb(39, 167, 55)",
    //         fontSize: "3vh",
    //         border: "0px solid white"
    //       }}
    //       onClick={nextFunc}
    //     >
    //       <Translate value="application.languageselect.presstostart" />
    //     </Button>
    //   </div>

    //   <div style={kioskverticalstyles_2.languageselect.qrdiv}>
    //     <div style={kioskverticalstyles_2.languageselect.qrelement}>
    //       <QRCode
    //         style={{
    //           height: "auto",
    //           width: "auto",
    //           maxWidth: "100%",
    //           maxHeight: "100%"
    //         }}
    //         size="600"
    //         fgColor="#27a737"
    //         logoImage="/img/ca.svg"
    //         qrStyle="dots"
    //         removeQrCodeBehindLogo="true"
    //         eyeRadius={{ outer: 5, inner: 1 }}
    //         logoPadding="3"
    //         //value="https://app.consignaction.com/qs/{{qrCode}}"
    //         value={env.CAAPP_URL + "/qs/" + qrCode}
    //       />
    //     </div>
    //     <div style={kioskverticalstyles_2.languageselect.qrtextelement}>
    //       <div
    //         class="fontBold"
    //         style={{
    //           fontSize: "2.8vh",
    //           textAlign: "center",
    //           marginBottom: "2vh"
    //         }}
    //       >
    //         <Translate value="application.languageselect.quickstart" />
    //       </div>
    //       <div
    //         class="fontNormal"
    //         style={{ fontSize: "1.7vh", textAlign: "center" }}
    //       >
    //         <Translate value="application.languageselect.scanwithapp" />
    //       </div>
    //     </div>
    //   </div>

    //   <div style={kioskverticalstyles_2.languageselect.languagediv}>
    //     <LanguageButtons />
    //   </div>
    // </div>

    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={kioskstyles.GridFullHeightFix}
    >
      <Grid item xs={12} sm={6} style={KioskStylesReact().flexLeftPanel}>
        <div style={kioskstyles.flexLeftPanelContents}>
          <div style={kioskstyles.flexParentPanel}>
            <div
              style={{
                ...kioskstyles.flexPanelLayoutD,
                justifyContent: "center",
                height: "100%"
              }}
            >
              <Logo />
              <KioskIDButton />
              <div
                style={{
                  textAlign: "center"
                }}
                onClick={nextFunc}
              >
                <div
                  class="fontBold"
                  style={{
                    fontSize: "clamp(1.5rem, 9.5vh, 4.3rem)",
                    textAlign: "center",
                    color: "white",
                    marginTop: "-6vh" //fix for text not reaching to top of div
                  }}
                >
                  <Translate value="application.languageselect.kioskname" />
                </div>
                <br />
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    color: "rgb(39, 167, 55)",
                    fontSize: "clamp(0.7rem, 4vh, 2.5rem)",
                    fontStyle: "italic",
                    border: "0px solid white"
                  }}
                >
                  <Translate value="application.languageselect.presstostart" />
                </Button>
              </div>
              <LanguageButtons />
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={KioskStylesReact().flexRightPanel}
        onClick={nextFunc}
      >
        <div style={kioskstyles.flexRightPanelContents}>
          <div style={kioskstyles.flexParentPanel}>
            <div
              style={{
                ...kioskstyles.flexPanelLayoutD,
                justifyContent: "center",
                height: "100%"
              }}
            >
              <SubtitleWrapper isNotMobile={isNotMobile}></SubtitleWrapper>
              <div style={{ height: "calc(100% - clamp(0px, 3.2vi, 48px))" }}>
                <SplashImage
                  trigger={translate("application.languageselect.presstostart")}
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
