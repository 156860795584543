import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";

import HorizontalLayout from "./Components/NumberOfBags/HorizontalLayout";
import VerticalLayout from "./Components/NumberOfBags/VerticalLayout";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";
import SocketIOContext from "../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import { WORKFLOW_MODES } from "../../constants";

import "./../../font.css";

let env = require("./../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function NumberOfBags({ authenticate }) {
  const theme = useTheme();
  const { simplepost } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const [numbags, setNumbags] = useState("1");
  //const { customer, setCustomer } = useContext(KioskContext);
  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const { getKioskConfiguration } = useContext(SocketIOContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  const [orientation, setOrientation] = useState(
    window.screen.orientation.type
  );

  const [workflowmode, setWorkflowmode] = useState(
    getKioskConfiguration().workflowMode
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };
  // const { navigation } = this.props;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      navigate("/");

      setLoading(false);
    }
  });

  const next = () => {
    Dlog("next called");

    Dlog(
      "DEBUG context Dump 3",
      customerFirstName,
      customerLastName,
      customerID,
      numBags,
      numLabels
    );

    //setNumBags(numbags);
    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      numbags, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    Dlog(
      "DEBUG context Dump 3-2",
      customerFirstName,
      customerLastName,
      customerID,
      numBags,
      numLabels
    );

    navigate("/numlabels");
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  const numbagpress = async event => {
    setNumbags(event.target.innerText);

    //Check workflowmode
    if (workflowmode === WORKFLOW_MODES.BAG_ONLY) {
      //Submit with the assumption that bags == labels
      let subscriptionID = localStorage.getItem("subscriptionID");
      let kioskID = localStorage.getItem("kioskID");

      const res = await simplepost("/kiosk/submit", {
        subscriptionID: subscriptionID,
        kioskID: kioskID,
        customerName: customerFirstName + " " + customerLastName,
        customerID: customerID,
        numBags: event.target.innerText,
        numLabels: event.target.innerText,
        sessionID: sessionID
      });

      Dlog("Number of Labels", res);

      // Show errorscreen if access is denied
      if (res.status !== 200) {
        //Kiosk has been disabled
        navigate("/errorscreen");
        return;
      }

      setAll(
        //customerName, //customerName
        customerFirstName, //customerFirstName
        customerLastName, //customerLastName
        customerID, //customerID
        event.target.innerText, //numBags
        event.target.innerText, //numLabels
        res.data.bagtagList, //labelsList
        sessionID //sessionID
      );

      navigate("/printlabels");
    } else {
      setAll(
        //customerName, //customerName
        customerFirstName, //customerFirstName
        customerLastName, //customerLastName
        customerID, //customerID
        event.target.innerText, //numBags
        null, //numLabels
        null, //labelsList
        sessionID //sessionID
      );

      navigate("/numlabels");
    }
  };

  //Return primary or secondary, depending if text matchs numbags
  const ispressed = buttonnumbag => {
    if (buttonnumbag === numbags) {
      return "secondary";
    }

    return "primary";
  };

  Dlog("locale", getLocale());

  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isLandscape = useMediaQuery("(orientation:landscape)");

  if (
    orientation == "landscape-primary" ||
    orientation == "landscape-secondary"
  ) {
    return (
      <HorizontalLayout
        numbagpress={numbagpress}
        workflowmode={workflowmode}
        getKioskConfiguration={getKioskConfiguration}
      />
    );
  } else {
    return (
      <VerticalLayout
        numbagpress={numbagpress}
        workflowmode={workflowmode}
        getKioskConfiguration={getKioskConfiguration}
      />
    );
  }
}

export default NumberOfBags;
