// import * as React from "react";
import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "./../../../../hooks/useSnackbar";
import _ from "lodash";

import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";
import AlertDialog from "../../../../components/Dialogs/AlertDialog";

import { useFormik } from "formik";
import * as Yup from "yup";

import { PASSWORD_REGEX } from "../../../../constants";

import HttpContext from "../../../../contexts/HTTP/HttpContext";

function ResetPasswordDialog({ user, open, handleConfirm, handleClose }) {
  const theme = useTheme();
  const { post, get } = useContext(HttpContext);

  const { openSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user) {
      formik.values.id = user._id;
    }

    formik.values.newpassword = "";
  }, [user]);

  const formik = useFormik({
    initialValues: {
      id: "",
      token: "",
      newpassword: ""
    },
    validationSchema: Yup.object({
      newpassword: Yup.string()
        .required("Password is a required field")
        .max(32, "Password can contain at most 32 characters")
        .matches(
          PASSWORD_REGEX,
          "Password must contain at least one uppercase character, one lowercase character, and one number"
        )
    }),
    onSubmit: async values => {
      setLoading(true);
      setShowPassword(false);

      const res = await post("/forcepassword", values);
      console.log("dialog /forcepassword", res);

      if (res.status !== 200) {
        openSnackbar({
          open: true,
          message: "Cannot create user - already exists",
          variant: "alert",
          alert: {
            color: "error"
          },
          close: false
        });
      }

      if (res.status === 200) {
        openSnackbar({
          open: true,
          message: "Created successfully",
          variant: "alert",
          alert: {
            color: "success"
          },
          close: false
        });
        doClose();
      }

      setLoading(false);
    }
  });

  const doClose = () => {
    formik.values.newpassword = "";
    handleClose();
  };

  return (
    <>
      <AlertDialog
        open={open}
        handleConfirm={() => {
          console.log("handleConfirm pressed");
          formik.handleSubmit();
        }}
        handleClose={doClose}
        title={"Update Password for " + (user ? user.email : "")}
        confirmButtonName="Confirm"
        closeButtonName="Cancel"
      >
        <br />

        <InputLabel htmlFor="newpassword">New Password</InputLabel>
        <OutlinedInput
          fullWidth
          id="newpassword"
          name="newpassword"
          label="newpassword"
          value={formik.values.newpassword}
          type={showPassword ? "text" : "password"}
          onChange={formik.handleChange}
          error={!_.isEmpty(formik.errors.newpassword)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={e => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div>{formik.errors.newpassword}</div>
      </AlertDialog>
    </>
  );
}

export default ResetPasswordDialog;
