import * as React from "react";

import { formatDate } from "../../../../../utils/misc";

import { TableBody, TableRow, TableCell } from "@mui/material";

import WorkflowDropdown from "./WorkflowDropdown";

import { WORKFLOW_MODES } from "../../../../../constants";

export default function KiosksListTableBody({
  subscriptions,
  filterKioskID,
  handleSetWorkflowModeKiosk
}) {
  return (
    <React.Fragment>
      <TableBody>
        {subscriptions
          ? subscriptions.kioskKeys
              .filter(
                kiosks =>
                  filterKioskID === "" || kiosks.kioskID.includes(filterKioskID)
              )
              .map(row => (
                <TableRow key={row.kioskID}>
                  <TableCell component="th" scope="row">
                    {row.kioskID}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.ip}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <WorkflowDropdown
                      initialValue={
                        row.kioskSettings
                          ? row.kioskSettings.workflowMode
                          : WORKFLOW_MODES.BAGS_AND_LABELS
                      }
                      handleSetWorkflowModeKiosk={handleSetWorkflowModeKiosk}
                      sessionID={subscriptions._id}
                      kioskID={row.kioskID}
                    />
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                            {row.bagtags.length}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.log.length}
                          </TableCell> */}
                  <TableCell component="th" scope="row">
                    {formatDate(row.lastAccessed, "YYYY-MMM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatDate(row.createdAt, "YYYY-MMM-DD HH:mm:ss")}
                  </TableCell>
                </TableRow>
              ))
          : ""}
      </TableBody>
    </React.Fragment>
  );
}
