import React, { useContext, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  Paper
} from "@mui/material";

import EnvironmentDisplay from "../../Kiosk/Components/Logo/EnvironmentDisplay";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import HttpContext from "../../../contexts/HTTP/HttpContext";

let env = require("../../../env");

function ResetPassword({ authenticate, props }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { post } = useContext(HttpContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  // let id = this.props.match.params.id;
  // let token = this.props.match.params.token;

  // console.log("ForgetPassword", this.props);
  // console.log("ForgetPassword", id, token);

  const { id, token } = useParams(); // Access parameters from the URL

  // Use id and token in your component
  console.log("ID:", id);
  console.log("Token:", token);

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      id: id,
      token: token
    },
    onSubmit: async values => {
      setLoading(true);

      const res = await post("/resetpassword", values);
      if (res.status >= 400) {
        setLoginError("Unable to reset password");
      } else {
        //Direct to Sent page
        navigate("/resetpassworddone");
        setLoginError("");
      }

      setLoading(false);
    }
  });

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "lightgrey" //Should be consignee Green
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //height: "20vh",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2vw"
        }}
      >
        <EnvironmentDisplay />
        <Typography variant="h4" style={{ margin: theme.spacing(2) }}>
          Consignaction Kiosk
        </Typography>{" "}
      </div>
      <Paper
        elevation={3}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "4vw"
        }}
      >
        <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }}>
          Reset Password
        </Typography>

        <OutlinedInput
          id="newpassword"
          name="newpassword"
          label="newpassword"
          value={formik.values.newpassword}
          type={showPassword ? "text" : "password"}
          onChange={formik.handleChange}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                disabled={loading}
                onClick={e => {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography
          variant="body2"
          style={{ color: "red", marginTop: theme.spacing(0.5) }}
        >
          {loginError}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          onClick={formik.handleSubmit}
          style={{ marginTop: theme.spacing(2) }}
        >
          Change Password
        </Button>
      </Paper>
      <span
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2vw",
          color: "grey"
        }}
      >
        {env.REACT_APP_VERSION}
      </span>
    </div>
  );
}

export default ResetPassword;
