import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "../../../hooks/useSnackbar";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // or any other adapter like AdapterDateFns
import dayjs from "dayjs";

import { useTheme } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";

import AlertDialog from "../../../components/Dialogs/AlertDialog";

import ContainerLoading from "../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../utils/misc";

import UpdateLogRow from "./ErrorLogs/ErrorLogRow";
import StatusDropdown from "./ErrorLogs/StatusDropdown";

import LocationCodeDropdown from "./Shared/LocationCodeDropdown";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

function SyncUpdateLogs() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [allLogs, setAllLogs] = useState([]);

  const { id } = useParams(); // Get the ID from the URL

  // const [filterInboundCode, setFilterInboundCode] = useState("");
  // const [filterStatus, setFilterStatus] = useState("");
  // const [filterStartDate, setFilterStartDate] = useState(
  //   dayjs().subtract(7, "day")
  //);
  const [filterEndDate, setFilterEndDate] = useState(dayjs());

  const { openSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const inboundCode = searchParams.get("inboundCode");
  const status = searchParams.get("status");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const [filterInboundCode, setFilterInboundCode] = useState(inboundCode);
  const [filterStatus, setFilterStatus] = useState(status);
  const [filterStartDate, setFilterStartDate] = useState(
    dayjs().subtract(2, "month")
  );

  const [showPayload, setShowPayload] = useState(false);
  const [payloadString, setPayloadString] = useState("");

  // useEffect(async () => {
  //   // Now you can use all these parameters to fetch data or filter results
  //   console.log("Required ID:", id);
  //   console.log("Optional inboundCode:", inboundCode);
  //   console.log("Optional status:", status);
  //   console.log("Optional date range:", startDate, "to", endDate);

  //   if (inboundCode) {
  //     setFilterInboundCode(inboundCode);
  //   }

  //   // Your data fetching or filtering logic here
  // }, [id, inboundCode, status, startDate, endDate]);

  const loadData = async () => {
    setLoading(true);

    let payload = {
      inboundCode: filterInboundCode,
      status: filterStatus,
      startDate: filterStartDate.toISOString(),
      endDate: filterEndDate.toISOString()
    };

    console.log("payload", payload);

    //Load data
    const res2 = await post("/kiosk/UpdateLogs", payload);

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to load sync logs",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }

    if (res2.status == 403 || res2.status == 401) {
      //logout and redirect to login
      await get("/logout");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }

    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      setAllLogs(subscriptionData.synclogs || []);
      console.log("loadDetails", subscriptionData.synclogs);

      //TESTING
      console.log("id via parameter", id);

      if (id !== undefined) {
        console.log("id not null");
      }

      setLoading(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    // Scroll to the element with the specified ID once the list is loaded
    if (loading === false && id) {
      const targetElement = document.getElementById(id);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [loading, id]);

  const loadDetails = async id => {};

  const handleActionShowData = async inboundData => {
    setPayloadString(inboundData);
    setShowPayload(true);
  };

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">Error Logs [{allLogs.length}]</Typography>
          <Typography>Sync updates from CA App</Typography>
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: theme.spacing(2)
          }}
        >
          <Typography variant="h4">Error Logs</Typography>
          <Typography variant="h5" style={{ marginLeft: theme.spacing(2) }}>
            <span style={{ color: "grey" }}>
              ({allLogs.length} result{allLogs.length == 1 ? "" : "s"})
            </span>
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: theme.spacing(2)
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1)
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Start Date"
              value={filterStartDate}
              onChange={newValue => {
                console.log("DateTimePicker", newValue);
                setFilterStartDate(newValue);
              }}
            />
            <DateTimePicker
              label="End Date"
              value={filterEndDate}
              onChange={newValue => {
                console.log("DateTimePicker", newValue);
                setFilterEndDate(newValue);
              }}
            />
          </LocalizationProvider>
          {/* <TextField
            id="bts"
            label="Code Search"
            value={filterInboundCode}
            onChange={event => {
              let newText = event.target.value;
              newText = newText.toUpperCase().trim();
              setFilterInboundCode(newText);
            }}
          /> */}
          <LocationCodeDropdown
            value={filterInboundCode}
            onChange={(e, val) => {
              setFilterInboundCode(val);
            }}
          />
          <StatusDropdown
            value={filterStatus}
            onChange={(e, val) => {
              setFilterStatus(val);
            }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => loadData()}
            disabled={loading}
          >
            <SearchIcon />
            Search
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell align="left">Completed</TableCell>
              <TableCell align="left">Location Code</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="left">Error</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allLogs.map(row => (
              <UpdateLogRow
                row={row}
                handleActionShowData={handleActionShowData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AlertDialog
        open={showPayload}
        handleClose={() => {
          setShowPayload(false);
          setPayloadString("");
        }}
        title="Inbound Data"
      >
        {/* <Paper elevation={3} sx={{ p: 2, marginBottom: theme.spacing(2) }}> */}
        <Paper elevation={2} sx={{ p: 2, backgroundColor: "lightgrey" }}>
          <Typography
            variant="body2"
            component="code"
            style={{ wordWrap: "break-word" }}
          >
            {payloadString}
          </Typography>
        </Paper>
      </AlertDialog>
    </Grid>
  );
}

export default SyncUpdateLogs;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
