import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";

import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TextField
} from "@mui/material";

import EditDialogTableBody from "./EditDialogTableBody";

import HttpContext from "../../../../contexts/HTTP/HttpContext";

function EditDialog({
  open,
  maxWidth = "sm",
  disableClose,
  handleClose,
  fullWidth = true,
  fullScreen = false,
  disableEnforceFocus = false,
  editDialogData,
  editDialogIndex
}) {
  const [updateData, setUpdateData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { get, post } = useContext(HttpContext);

  useEffect(() => {
    console.log("editDialogData", editDialogData);
    if (editDialogData !== null) {
      console.log(
        "Object.keys(editDialogData.settings)",
        Object.keys(editDialogData.settings)
      );
    }

    setUpdateData(editDialogData);
  }, [editDialogData]);

  async function updateValueFunc(key, value) {
    let data = { ...updateData };
    console.log("updateValueFunc before", key, data.settings[key], data);
    data.settings[key] = value;
    setUpdateData(data);
    console.log("updateValueFunc after", key, data.settings[key], data);
  }

  async function saveSetting() {
    setLoading(true);

    //const myJSON = JSON.parse(JSON.stringify(updateData));
    const myJSON = Object.assign({}, updateData);

    console.log("myJSON", myJSON);
    //Load data
    //const res2 = await post("/kiosk/configuration/123", myJSON);
    const res2 = await post("/kiosk/configuration/123", myJSON);

    if (res2.status === 401) {
      console.log("ERROR, please relog in");
    }

    if (res2.status === 200) {
      const data = _.get(res2, "data");
      console.log("saveSetting", data);

      handleClose();
    }

    setLoading(false);
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      disableEnforceFocus={disableEnforceFocus}
      keepMounted
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        handleClose();
      }}
      maxWidth={maxWidth}
      //TransitionComponent={FadeTransition}
    >
      <DialogContent style={{}}>
        <div>Settings for {updateData ? updateData.name : ""}</div>
        <TableContainer>
          <Table
            style={{
              width: "100%"
              //display: loading ? "none" : null
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Key</TableCell>
                <TableCell align="left">Value</TableCell>
              </TableRow>
            </TableHead>
            <EditDialogTableBody
              updateData={updateData}
              updateValueFunc={updateValueFunc}
            />
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button
            disabled={disableClose}
            variant="outlined"
            color="consignactionRed"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              console.log("updateData", updateData);
              saveSetting();
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default EditDialog;
