import React, { useContext, useEffect, useState } from "react";
import useSnackbar from "./../../../hooks/useSnackbar";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import Add from "@mui/icons-material/Add";

import AlertDialog from "../../../components/Dialogs/AlertDialog";
import ContainerLoading from "../../../components/Loading/ContainerLoading";

import UserRow from "./Users/UserRow";

import CreateUserDialog from "./Users/CreateUserDialog";
import ResetPasswordDialog from "./Users/ResetPasswordDialog";

import HttpContext from "../../../contexts/HTTP/HttpContext";

function Users() {
  const theme = useTheme();
  const { get, post } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [createUserDialogOpened, setCreateUserDialogOpened] = useState(false);

  const [resetPasswordDialogOpened, setResetPasswordDialogOpened] = useState(
    false
  );

  const [
    showDisableUserConfirmationDialog,
    setShowDisableUserConfirmationDialog
  ] = useState(false);

  const [disableUserParams, setDisableUserParams] = useState(null);
  const [TargetUserID, setTargetUserID] = useState(null);
  const [TargetUser, setTargetUser] = useState(null);

  const [userData, setUserData] = useState(null);

  const { openSnackbar } = useSnackbar();

  const loadData = async () => {
    setLoading(true);

    //Load data
    const res2 = await get("/users");

    if (res2.status !== 200) {
      openSnackbar({
        open: true,
        message: "Unable to load user data",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }

    if (res2.status == 403 || res2.status == 401) {
      //logout and redirect to login
      await get("/logout");
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }

    if (res2.status === 200) {
      const data = _.get(res2, "data");

      await setUserData(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const handleOpenResetPassword = id => {
    setTargetUserID(id);
    const udata = userData.find(item => item._id === id);

    setTargetUser(udata);

    setResetPasswordDialogOpened(true);
  };

  const handleActionResetPassword = async (id, newpassword) => {
    let payload = { id: id, newpassword: newpassword };
    console.log("handleActionResetPassword");

    console.log("payload", payload);

    const res2 = await post("/forcepassword", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      console.log("forcepassword res2", res2);

      //SInce format can change, just reload from server
      await loadData();
    } else {
      openSnackbar({
        open: true,
        message: "Unable to update password. Please try again.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }
  };

  const handleActionDisableAccount = async () => {
    let payload = {
      id: disableUserParams.id,
      active: disableUserParams.active
    };

    console.log("payload", payload);

    const res2 = await post("/disableaccount", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //SInce format can change, just reload from server
      await loadData();
      setShowDisableUserConfirmationDialog(false);
    } else {
      openSnackbar({
        open: true,
        message: "Unable to update account access. Please try again.",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }
  };

  // Not currently used
  const handleActionForceLogoutAccount = async id => {
    let payload = { id: id };

    console.log("payload", payload);

    const res2 = await post("/forcelogout", payload);
    if (res2.status === 200) {
      const subscriptionData = _.get(res2, "data");

      //SInce format can change, just reload from server
      await loadData();
    }
  };

  const handleOpenDisableConfirmation = (id, active, email) => {
    let payload = { id: id, active: active, email: email };
    setDisableUserParams(payload);

    setShowDisableUserConfirmationDialog(true);
  };

  const disableConfirmationText = () => {
    console.log("disableConfirmationText");
    if (disableUserParams) {
      return (
        "Are you sure you want to " +
        (disableUserParams.active ? "reactivate" : "disable") +
        " access for " +
        disableUserParams.email +
        "?"
      );
    } else {
      return "";
    }
  };

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: theme.spacing(2)
            }}
          >
            <Typography variant="h4">Users</Typography>
            <Typography variant="h5" style={{ marginLeft: theme.spacing(2) }}>
              {userData ? (
                <span style={{ color: "grey" }}>
                  ({userData.length} result{userData.length == 1 ? "" : "s"})
                </span>
              ) : (
                <></>
              )}
            </Typography>
          </div>
          {/* <Typography>View and delete your Users</Typography> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: theme.spacing(2)
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1)
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setCreateUserDialogOpened(true);
            }}
            disabled={loading}
          >
            <Add style={{ marginRight: theme.spacing(1) }} />
            Register User
          </Button>
        </div>
      </div>

      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Active</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Created On</TableCell>
              <TableCell align="left">Last Updated</TableCell>

              <TableCell align="left">Last Login</TableCell>

              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData !== null ? (
              userData.map(row => (
                <UserRow
                  row={row}
                  handleActionResetPassword={handleOpenResetPassword}
                  // handleActionForceLogoutAccount={
                  //   handleActionForceLogoutAccount
                  // }
                  handleOpenDisableConfirmation={handleOpenDisableConfirmation}
                />
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateUserDialog
        open={createUserDialogOpened}
        handleClose={() => setCreateUserDialogOpened(false)}
        handleRefresh={loadData}
        handleActionResetPassword={id => {
          handleActionResetPassword(id);
        }}
      />
      <ResetPasswordDialog
        user={TargetUser}
        open={resetPasswordDialogOpened}
        handleClose={() => setResetPasswordDialogOpened(false)}
        handleActionResetPassword={handleActionResetPassword}
      />
      <AlertDialog
        open={showDisableUserConfirmationDialog}
        handleClose={() => {
          setShowDisableUserConfirmationDialog(false);
        }}
        handleConfirm={handleActionDisableAccount}
        title={
          disableUserParams && disableUserParams.active
            ? "Reactivate Account Access"
            : "Revoke Account Access"
        }
        closeButtonName="Cancel"
      >
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          {disableConfirmationText()}
        </Typography>
      </AlertDialog>
    </Grid>
  );
}

export default Users;
