import React, { useContext, useEffect, useState } from "react";
import HttpContext from "../../../../contexts/HTTP/HttpContext";

import {
  FormControl,
  InputLabel,
  TextField,
  Autocomplete
} from "@mui/material";

function LocationCodeDropdown({ value, onChange }) {
  const [locationList, setLocationList] = useState([]);
  const { get, post } = useContext(HttpContext);

  useEffect(() => {
    async function getDropLoc() {
      const res = await get("/kiosk/getAllLocationCodes");
      if (res.status === 200) {
        let locationCodes = res.data;
        setLocationList(locationCodes);
      }
    }

    getDropLoc();
  }, []);

  return (
    <div style={{ minWidth: "10rem" }}>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          id="cblocationoptions"
          options={locationList}
          onChange={onChange}
          defaultValue={value}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              name="name"
              label="Location Code"
            />
          )}
        />
      </FormControl>
    </div>
  );
}

export default LocationCodeDropdown;
