import { setTranslations, setLocale } from "react-i18nify";

export const initializeTranslations = async (url, params) => {
  setTranslations({
    en: {
      application: {
        title: "Awesome app with i18n!",
        hello: "Hello, %{name}!",
        welcome: "Welcome, %{name}!",
        thanks: "Thanks, %{name}!",
        next: "next",
        back: "Back",
        end: "End",
        no: "No",
        yes: "Yes",
        errors: {
          serverunavailable: "Server temporarily unavailable.",
          unknownerror: "An Unknown Error has occurred.",
          invalidcredentials: "Invalid credentials provided."
        },
        kioskdetail: {
          youareat: "You are at %{name} located on %{address}",
          youareatV: "You are located at %{address}",
          returningnumbags_1: "You are returning %{count} bag.",
          returningnumbags: "You are returning %{count} bags.",
          returningnumlabels_1: "You are printing %{count} sticker.",
          returningnumlabels: "You are printing %{count} stickers.",
          returning: "You are returning ",
          numbags_1: "%{count} bag",
          numbags: "%{count} bags",
          print: "printing",
          numlabels_1: "%{count} label",
          numlabels: "%{count} labels",
          errors: {
            unabletoprocesskey:
              "An error occurred while processing your Authorization Key.",
            keyerror:
              "An error occurred while processing your Authorization Key. Please check your authorization key."
          }
        },
        configuration: {
          configuration: "Configuration",
          donthavekioskkey: "Don't have a Kiosk Key?",
          enterkey: "Please enter the kiosk key to start",
          printerdebug: "Printer Debug"
        },
        languageselect: {
          kioskname: "Bag Deposit Kiosk",
          presstostart: "Press here to start",
          selectlanguage: "Please select a Language:",
          english: "English",
          french: "Français",
          quickstart: "Quick Start",
          scanwithapp:
            "Quickly authenticate by scanning the QR code with the Consignaction App."
          // scanwithapp:
          //   "Scan the QR code using the Consignaction app to get started"
        },
        phonenumber: {
          enterphonenumber: "Enter your Phone Number",
          pleasesignin: "Log in with your phone number",
          welcome: "Welcome!",
          donthaveaccount: "Don't have an account?",
          signinwithphone: "Sign in with phone number",
          scanqr: "Scan QR code to register",
          returnfor: "You are returning containers for:",
          isthiscorrect: "Is this correct?",
          urllangextension: "?lang=en",
          enterslowly: "Please enter your number slowly",
          errors: {
            unablefetchaccount:
              "An error occurred while fetching your account.",
            invalidphone: "Invalid telephone number. Please try again."
          }
        },

        numberofbags: {
          selectnumbags: "How many bags are you returning?",
          havemorebags: "Do you have more than %{count} bags?",
          multipleinteractions:
            "Yes. You will need to make more than one transaction.",
          needonlybags: "Do you just need stickers?",
          selectzerobags: "Yes. Please select 0 bags."
        },
        numberoflabels: {
          instructions:
            "To ensure you are credited, attach a label to each bag.",
          selectnumlabels: "How many stickers do you want to print?",
          bagsalreadylabelled: "Are all your bags identified?",
          selectzerobags: "Yes. Please select 0.",
          cantakelabelshome: "Can you take labels home?",
          uselabelsnexttime: "Yes, use them next time to speed up the drop."
        },
        printing: {
          printing: "Labels are currently printing",
          readytoprint: "Ready to Print",
          taptoprint: "Press here to Print"
        },
        thankyou: {
          thankyou: "Thank You.",
          seeyouagain: "We hope to see you again, %{name}!",
          labelyourbags: "Identify your bags",
          attachlabeltobags:
            "Attach your stickers by pressing them firmly onto your bags.",
          dropindroparea: "Express Bag Drop",
          placeindesignatedarea:
            "Drop off your bags in the express return area.",
          thatsit: "You have finished!",
          refundcredited:
            "You will receive the credit in your account within 7 days.",
          finish: "Finish"
        },
        errorscreen: {
          error: "Error",
          errorhasoccured: "Kiosk is connected to an invalid collector.",
          contactoperator:
            "Please enter a valid kiosk key or contact technical support."
        },
        printerdebug: {
          printtestlabel: "Print Test Label",
          returntoconfigure: "Return To Configure"
        }
      },
      date: {
        long: "MMMM do, yyyy"
      },
      export: "Export %{count} items",
      export_0: "Nothing to export",
      export_1: "Export %{count} item",
      two_lines: "<div>Line 1<br />Line 2<div>"
    },
    fr: {
      application: {
        title: "Toffe app met i18n!",
        hello: "Bonjour, %{name}!",
        welcome: "Bienvenue, %{name}!",
        thanks: "Merci, %{name}!",
        next: "suivant",
        back: "Retour",
        end: "Fin",
        no: "Non",
        yes: "Oui",
        errors: {
          serverunavailable: "Serveur temporairement indisponible.",
          unknownerror: "Une erreur inconnue s'est produite.",
          invalidcredentials:
            "Informations d'identification non valides fournies."
        },
        kioskdetail: {
          youareat: "Vous êtes à %{name} situé sur %{address}",
          youareatV: "Vous êtes situé au %{address}",
          returningnumbags_1: "Vous retournez %{count} sac.",
          returningnumbags: "Vous retournez %{count} sacs.",
          returningnumlabels_1: "Vous imprimez %{count} autocollant.",
          returningnumlabels: "Vous imprimez %{count} autocollants.",
          returning: "Vous retournez ",
          numbags_1: "%{count} sac",
          numbags: "%{count} sacs",
          print: "imprimant",
          numlabels_1: "%{count} autocollant.",
          numlabels: "%{count} autocollants.",
          errors: {
            unabletoprocesskey:
              "Une erreur s'est produite lors du traitement de votre clé d'autorisation.",

            keyerror:
              "Une erreur s'est produite lors du traitement de votre clé d'autorisation. Veuillez vérifier votre clé d'autorisation."
          }
        },
        configuration: {
          configuration: "Configuration",
          donthavekioskkey: "Vous n'avez pas de clé de kiosque?",
          enterkey: "Veuillez entrer la clé du kiosque pour commencer",
          printerdebug: "Débogage de l'imprimante"
        },
        languageselect: {
          kioskname: "Retour Express Dépôt de sacs",
          presstostart: "Appuyez ici pour débuter",
          selectlanguage: "Veuillez sélectionner une langue:",
          english: "English",
          french: "Français",
          quickstart: "Début rapide",
          scanwithapp:
            "Identifiez-vous rapidement en balayant le code QR avec l’application Consignaction."
          // scanwithapp:
          //   "Scannez le code QR à l'aide de l'application Consignaction pour commencer"
        },
        phonenumber: {
          enterphonenumber: "Entrez votre numéro de téléphone",
          pleasesignin: "Connectez-vous avec votre numéro de téléphone.",
          welcome: "Bienvenue!",
          donthaveaccount: "Vous n'avez pas de compte?",
          signinwithphone: "Entrez votre numéro de téléphone",
          scanqr:
            "Balayez le code QR pour télécharger l’application mobile et créer un compte",
          returnfor: "Vous retournez des sacs pour:",
          isthiscorrect: "Est-ce correct?",
          urllangextension: "?lang=fr",
          enterslowly: "Veuillez entrer votre numéro doucement",
          errors: {
            unablefetchaccount:
              "Une erreur s'est produite lors de la récupération de votre compte.",
            invalidphone: "Numéro de téléphone invalide. Veuillez réessayer."
          }
        },
        numberofbags: {
          selectnumbags: "Combien de sacs retournez-vous?",
          havemorebags: "Avez-vous plus de %{count} sacs?",
          multipleinteractions:
            "Oui. Vous devrez effectuer plus d’une transaction.",
          needonlybags: "Avez-vous simplement besoin d’autocollants?",
          selectzerobags: "Oui. Veuillez sélectionner 0 sacs."
        },
        numberoflabels: {
          instructions:
            "Pour vous assurer d'être crédité, joignez une étiquette sur chaque sac.",
          selectnumlabels: "Combien d’autocollants voulez-vous imprimer?",
          bagsalreadylabelled: "Avez-vous des sacs déjà identifiés?",
          selectzerobags: "Oui. Veuillez sélectionner 0.",
          cantakelabelshome: "Pouvez-vous emporter des étiquettes à la maison?",
          uselabelsnexttime:
            "Oui, utilisez-les la prochaines fois pour accélérer le dépôt."
        },
        printing: {
          printing: "Les étiquettes sont en cours d'impression",
          readytoprint: "Prêt à imprimer",
          taptoprint: "Cliquez ici pour imprimer"
        },
        thankyou: {
          thankyou: "Merci!",
          seeyouagain: "Nous espérons vous revoir bientôt, %{name}!",
          labelyourbags: "Identifiez vos sacs",
          attachlabeltobags:
            "Apposez vos autocollants en les appuyant fermement sur vos sacs.",
          dropindroparea: "Dépôt de sac",
          placeindesignatedarea:
            "Déposez vos sacs dans la zone du retour express.",
          thatsit: "Vous avez terminé!",
          refundcredited:
            "Vous recevrez le crédit dans votre compte d’ici 7 jours.",
          finish: "Terminer"
        },
        errorscreen: {
          error: "Erreur",
          errorhasoccured:
            "Le kiosque est connecté à un collecteur non valide.",
          contactoperator:
            "Veuillez saisir une clé de kiosque valide ou contacter le support technique."
        },
        printerdebug: {
          printtestlabel: "Imprimer l'étiquette de test",
          returntoconfigure: "Retour à la configuration"
        }
      },
      date: {
        long: "d MMMM yyyy"
      },
      export: "Exporteer %{count} dingen",
      export_0: "Niks te exporteren",
      export_1: "Exporteer %{count} ding",
      two_lines: "<div>Regel 1<br />Regel 2</div>"
    }
  });

  setLocale("fr");
};
