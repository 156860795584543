import { forwardRef } from "react";
import {
  Fade,
  Slide,
  Box,
  Autocomplete,
  TextField,
  Chip,
  Tooltip
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const FadeTransition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

export const SlideUpTransition = forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} direction="up" {...props} />;
});

export const Section = ({ title, children, theme }) => (
  <Box
    component="fieldset"
    sx={{
      borderColor: theme.palette.grey[50],
      padding: 3,
      paddingTop: 2.5,
      borderRadius: "0.25rem"
    }}
  >
    {title && (
      <legend
        style={{
          fontSize: theme.typography.body1.fontSize,
          fontFamily: theme.typography.body1.fontFamily
        }}
      >
        {title}
      </legend>
    )}
    {children}
  </Box>
);

export const TagInput = ({
  value,
  setValue,
  label,
  helperText,
  disableClearable = false
}) => (
  <Autocomplete
    multiple
    disableClearable={disableClearable}
    options={[]}
    onChange={(event, val, reason) => {
      if (["createOption", "removeOption", "clear"].includes(reason)) {
        setValue(val);
      }
    }}
    value={value}
    freeSolo
    renderTags={(value, getTagProps) =>
      value.map((option, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return <Chip label={option} key={key} {...tagProps} />;
      })
    }
    renderInput={params => (
      <TextField
        {...params}
        label={label || "Tags"}
        placeholder="Type and press enter to add"
        helperText={helperText}
      />
    )}
  />
);

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));
