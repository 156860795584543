import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Dlog } from "./../../../../utils/Dlog";

import HttpContext from "../../../../contexts/HTTP/HttpContext";
import KioskContext from "../../../../contexts/Kiosk/KioskContext";

import { useNavigate } from "react-router-dom";

import { Translate, Localize, translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { setTranslations, setLocale } from "react-i18nify";
import QRCode from "react-qr-code";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import IdleTimerDisplay from "./../Shared/IdleTimerDisplay_V";
import LanguageButtons from "./../Shared/LanguageButtons";
import PhoneKeypad from "./../CustomerPhoneNumber/PhoneKeypad";
import PhoneConfirm from "./../CustomerPhoneNumber/PhoneConfirm";
import PhoneDisplay from "./../CustomerPhoneNumber/PhoneDisplay_V";
import BackspaceButton from "./../CustomerPhoneNumber/BackspaceButton";
import CircleButton from "./../Buttons/CircleButton_V";

import { kioskverticalstyles_2 } from "./../../../../kioskverticalstyles_2";

import {
  Button,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  ButtonGroup
} from "@mui/material";

import KioskStepper from "./../../Components/Stepper/KioskStepper_V";
import Logo from "./../../Components/Logo_V";

import formatFirstNameLastInitial from "./../../HelperFunctions/formatFirstNameLastInitial";

import { useTheme } from "@mui/material/styles";

import { kioskstyles } from "./../../../../kioskstyles"; //TEMP

let env = require("./../../../../env");

function displayError(errorCode) {
  switch (errorCode) {
    case 400:
      return translate("application.phonenumber.errors.unablefetchaccount");
    case 401:
      return translate("application.phonenumber.errors.invalidphone");
    case 404:
      return translate("application.errors.invalidcredentials");
    case 503:
      return translate("application.errors.serverunavailable");
    default:
      return translate("application.errors.unknownerror");
  }
}

export default function VerticalLayout(props) {
  const {
    setKioskkeyFunc,
    nextFunc,
    loading,
    //errorMessage,
    isNotMobile,
    kioskkey,
    numbagpress,
    getKioskConfiguration
  } = props;

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setAll,
    customerReset
  } = useContext(KioskContext);

  const { simplepost } = useContext(HttpContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const [phonenumber, setPhonenumber] = useState("");
  const [showconfirmdialog, setShowconfirmdialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const keypadDone = async () => {
    console.log("next called");

    //Attempt Kiosk Login
    const res = await simplepost("/kiosk/getCustomer", {
      type: "phone",
      value: phonenumber
    });

    //console.log("CPN DEBUG res.data", res.data);

    //var customerName = res.data.name;
    var customerFirstName = res.data.firstName;
    var customerLastName = res.data.lastName;
    var customerID = res.data.uniqueID;

    if (res.status >= 400) {
      setErrorMessage(displayError(res.status));
      return;
    }

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      null, //numBags
      null, //numLabels
      null, //labelsList
      sessionID //sessionID
    );

    setErrorMessage("");
    setShowconfirmdialog(true);
  };

  const hideConfirmDialog = async () => {
    setPhonenumber("");
    setShowconfirmdialog(false);
  };

  const displaystyleForconfirmdialog = async () => {
    Dlog("displaystyleForconfirmdialog", showconfirmdialog);

    if (showconfirmdialog) {
      return { display: "none" };
    } else {
      return { display: "block" };
    }
  };

  const backspacepress = async () => {
    let newNumber = phonenumber;

    if (newNumber.length > 0) {
      newNumber = newNumber.substring(0, newNumber.length - 1);
    }

    setPhonenumber(newNumber);
  };

  const next = async () => {
    navigate("/language");
  };

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  const locationAddress = localStorage.getItem("LocationAddress");
  const locationName = localStorage.getItem("LocationName");

  return (
    <IdleTimerProvider timeout={30000} onIdle={onIdle}>
      <div style={kioskverticalstyles_2.flexBackground}>
        <div style={kioskverticalstyles_2.logo}>
          <center>
            <Logo />
          </center>
        </div>

        <div style={kioskverticalstyles_2.instructionDiv}>
          <div style={kioskverticalstyles_2.instructionVerticallyAlignDiv}>
            <Translate
              value="application.thanks"
              name={formatFirstNameLastInitial(
                customerFirstName,
                customerLastName
              )}
            />
            <br />
            <Translate
              value="application.kioskdetail.youareatV"
              name={locationName}
              address={locationAddress}
            />
            .
            <br />
            <Translate value="application.kioskdetail.returning" />
            <b>
              <Translate
                value="application.kioskdetail.numbags"
                count={numBags}
              />
              ,{" "}
            </b>
            <Translate value="application.kioskdetail.print" />{" "}
            <b>
              <Translate
                value="application.kioskdetail.numlabels"
                count={numLabels}
              />
            </b>
          </div>
        </div>

        <div
          style={{
            ...kioskverticalstyles_2.whiteDiv,
            ...kioskverticalstyles_2.flexcenterDiv
          }}
        >
          <div
            style={{
              marginTop: "5vh",
              height: "7.7vh",
              marginBottom: "7.7vh",
              width: "83vw"
            }}
          >
            <div
              class="fontSecondaryText"
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "3.4vh",
                marginBottom: "4.5vh"
              }}
            >
              <Translate value="application.thankyou.thankyou" />
            </div>
            <center>
              <div>
                <CheckBoxOutlinedIcon style={{ fontSize: "6vh" }} />
              </div>
              <div style={{ fontSize: "2vh", marginBottom: "1.5vh" }}>
                <div>
                  <b>
                    <Translate value="application.thankyou.labelyourbags" />
                  </b>
                </div>
                <div>
                  <Translate value="application.thankyou.attachlabeltobags" />
                </div>
              </div>
              <div>
                <ArrowDropDownCircleIcon style={{ fontSize: "6vh" }} />
              </div>
              <div style={{ fontSize: "2vh", marginBottom: "1.5vh" }}>
                <div>
                  <b>
                    <Translate value="application.thankyou.dropindroparea" />
                  </b>
                </div>
                <div>
                  <Translate value="application.thankyou.placeindesignatedarea" />
                </div>
              </div>
              <div>
                <SentimentSatisfiedAltIcon style={{ fontSize: "6vh" }} />
              </div>
              <div style={{ fontSize: "2vh" }}>
                <div>
                  <b>
                    <Translate value="application.thankyou.thatsit" />
                  </b>
                </div>
                <div>
                  <Translate value="application.thankyou.refundcredited" />
                </div>
              </div>
            </center>
          </div>

          {/* <span
            class="fontNormal"
            style={{
              ...kioskstyles.blackBodyText
            }}
          >
            <b>
              <Translate value="application.thankyou.dropindroparea" />
            </b>{" "}
            <span style={kioskstyles.HelperTextColor}>
              <Translate value="application.thankyou.placeindesignatedarea" />
            </span>
          </span>
          <br />
          <br />
          <div>
            <center>
              <SentimentSatisfiedAltIcon
                style={{ fontSize: "clamp(30px, 8vh, 120px)" }}
              />
            </center>
          </div>

          <span
            class="fontNormal"
            style={{
              ...kioskstyles.blackBodyText
            }}
          >
            <b>
              <Translate value="application.thankyou.thatsit" />
            </b>{" "}
            <span style={kioskstyles.HelperTextColor}>
              <Translate value="application.thankyou.refundcredited" />
            </span>
          </span> */}
        </div>

        <div style={kioskverticalstyles_2.footerDiv}>
          {/* <KioskStepper activeStep={3} backUrl={"/numlabels"} /> getKioskConfiguration={getKioskConfiguration}*/}
          <Button
            variant="contained"
            style={{
              height: "6vh",
              width: "30vw",
              fontSize: "2vh",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            onClick={next}
          >
            <Translate value="application.thankyou.finish" />
          </Button>
        </div>
      </div>
      <IdleTimerDisplay />
    </IdleTimerProvider>
  );
}
