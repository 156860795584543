import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function AlertDialog(props) {
  const {
    open,
    title,
    disableClose,
    disableConfirm,
    handleClose,
    closeButtonName,
    handleConfirm,
    confirmButtonName,
    maxWidth = "sm"
  } = props;

  console.log("maxWidth", maxWidth);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {handleConfirm && (
          <Button
            disabled={disableConfirm}
            color="secondary"
            onClick={handleConfirm}
          >
            {confirmButtonName || "Confirm"}
          </Button>
        )}
        <Button disabled={disableClose} onClick={handleClose}>
          {closeButtonName || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
