import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Translate, Localize, getLocale } from "react-i18nify";

import HorizontalLayout from "./Components/NumberOfLabels/HorizontalLayout";
import VerticalLayout from "./Components/NumberOfLabels/VerticalLayout";

import formatFirstNameLastInitial from "./HelperFunctions/formatFirstNameLastInitial";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTheme } from "@mui/material/styles";

import { Dlog } from "./../../utils/Dlog";

import HttpContext from "../../contexts/HTTP/HttpContext";
import KioskContext from "../../contexts/Kiosk/KioskContext";
import SocketIOContext from "../../contexts/Kiosk/SocketProvider/SocketIOContext";

import { useNavigate } from "react-router-dom";

import { IdleTimerContext, IdleTimerProvider } from "react-idle-timer";

import "./../../font.css";

let env = require("./../../env");

const ERRORS = {
  400: "An error occurred while processing your login",
  401: "Invalid credentials provided",
  404: "Invalid credentials provided"
};

function NumberOfLabels({ authenticate }) {
  const theme = useTheme();
  const { simplepost } = useContext(HttpContext);
  const { getKioskConfiguration } = useContext(SocketIOContext);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState("");

  const [numlabels, setNumlabels] = useState("1");

  const {
    //customerName,
    customerFirstName,
    customerLastName,
    customerID,
    setCustomerNameAndID,
    numBags,
    setNumBags,
    numLabels,
    setLabels,
    sessionID,
    setlabelsList,
    setAll,
    customerReset
  } = useContext(KioskContext);

  //params
  // const { customerName } = route.params;

  const navigate = useNavigate();

  const [orientation, setOrientation] = useState(
    window.screen.orientation.type
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  const onIdle = async () => {
    if (env.IDLETIMER) {
      navigate("/language");
    }
  };

  const numbagpress = async event => {
    let subscriptionID = localStorage.getItem("subscriptionID");
    let kioskID = localStorage.getItem("kioskID");

    const res = await simplepost("/kiosk/submit", {
      subscriptionID: subscriptionID,
      kioskID: kioskID,
      customerName: customerFirstName + " " + customerLastName,
      customerID: customerID,
      numBags: numBags,
      numLabels: event.target.innerText,
      sessionID: sessionID
    });

    Dlog("Number of Labels", res);

    // Show errorscreen if access is denied
    if (res.status !== 200) {
      //Kiosk has been disabled
      navigate("/errorscreen");
      return;
    }

    setAll(
      //customerName, //customerName
      customerFirstName, //customerFirstName
      customerLastName, //customerLastName
      customerID, //customerID
      numBags, //numBags
      event.target.innerText, //numLabels
      res.data.bagtagList, //labelsList
      sessionID //sessionID
    );

    navigate("/printlabels");
  };

  //Return primary or secondary, depending if text matchs numbags
  const ispressed = buttonnumlabels => {
    if (buttonnumlabels === numlabels) {
      return "secondary";
    }

    return "primary";
  };

  const isNotMobile = useMediaQuery("(min-width:600px)");
  const isLandscape = useMediaQuery("(orientation:landscape)");

  if (
    orientation == "landscape-primary" ||
    orientation == "landscape-secondary"
  ) {
    return (
      <HorizontalLayout
        numbagpress={numbagpress}
        numBags={numBags}
        getKioskConfiguration={getKioskConfiguration}
      />
    );
  } else {
    return (
      <VerticalLayout
        numbagpress={numbagpress}
        numBags={numBags}
        getKioskConfiguration={getKioskConfiguration}
      />
    );
  }
}

export default NumberOfLabels;
