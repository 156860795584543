import * as React from "react";
import Circle from "@mui/icons-material/Circle";

import { formatDate } from "../../../../utils/misc";

import { Button, TableRow, TableCell, Tooltip } from "@mui/material";

import LockResetIcon from "@mui/icons-material/LockReset";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BlockIcon from "@mui/icons-material/Block";
import BoltIcon from "@mui/icons-material/Bolt";

function UserRow({
  row,
  handleActionResetPassword,
  //handleActionForceLogoutAccount,
  handleOpenDisableConfirmation
}) {
  return (
    <TableRow key={row.locationCode}>
      <TableCell align="center">
        {row.active ? (
          <Circle style={{ color: "lightgreen" }} />
        ) : (
          <Circle style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.email}
      </TableCell>
      <TableCell align="left">
        {formatDate(row.createdAt, "YYYY-MMM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="left">
        {formatDate(row.updatedAt, "YYYY-MMM-DD HH:mm:ss")}
      </TableCell>
      <TableCell align="left">
        {row.lastLoginUTC
          ? formatDate(row.lastLoginUTC, "YYYY-MMM-DD HH:mm:ss")
          : "N/A"}
      </TableCell>

      {/* Force Log Out Not Used anymore */}
      {/* <TableCell align="center">
        <div>
          <Button
            variant="outlined"
            onClick={async () => {
              handleActionForceLogoutAccount(row._id);
            }}
          >
            <BoltIcon
              id="fade-button"
            />
          </Button>
        </div>
      </TableCell> */}

      {/* Actions */}
      <TableCell align="center">
        <div>
          <Tooltip title={"Reset Password"} arrow placement="top">
            <Button
              variant="outlined"
              onClick={async () => {
                handleActionResetPassword(row._id);
              }}
            >
              <LockResetIcon id="fade-button" />
            </Button>
          </Tooltip>

          <Tooltip
            title={
              row.active
                ? "Disable Account Access"
                : "Reactivate Account Access"
            }
            arrow
            placement="top"
          >
            <Button
              variant="outlined"
              onClick={async () => {
                handleOpenDisableConfirmation(row._id, !row.active, row.email);
              }}
              style={{ marginLeft: "2px" }}
            >
              {row.active ? (
                <BlockIcon id="fade-button" />
              ) : (
                <LockOpenIcon id="fade-button" />
              )}
            </Button>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default UserRow;
