import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerLoading from "../../../components/Loading/ContainerLoading";

import { formatDate } from "../../../utils/misc";

import UpdateLogRow from "./ErrorLogs/ErrorLogRow";

import EditDialog from "./Configuration/EditDialog";

import { KEY_NAME_REGEX } from "../../../constants";

import HttpContext from "../../../contexts/HTTP/HttpContext";

import moment from "moment";

function Configuration() {
  const theme = useTheme();
  const { get } = useContext(HttpContext);

  const [loading, setLoading] = useState(true);

  const [editDialogIsOpened, setEditDialogIsOpened] = useState(false);
  const [editDialogData, setEditDialogData] = useState(null);
  const [editDialogIndex, setEditDialogIndex] = useState(null);

  const [configurationList, setConfigurationList] = useState([]);

  const loadData = async () => {
    setLoading(true);

    //Load data
    const res2 = await get("/kiosk/getConfiguration/123");

    if (res2.status === 401) {
      console.log("ERROR, please relog in");
    }

    if (res2.status === 200) {
      const data = _.get(res2, "data");

      setConfigurationList(data || []);
      console.log("loadDetails", data);
    }

    setLoading(false);
  };

  const EditSettings = index => {
    console.log("Configuration.EditSettings() index", index);
    setEditDialogIndex(index);
    setEditDialogData(configurationList[index]);
    setEditDialogIsOpened(true);
  };

  const loadDetails = async id => {};

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <Grid
      style={{
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        width: "100%",
        backgroundColor: colors.grey[100],
        borderRadius: "10px"
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4">
            Configuration Template [{configurationList.length}]
          </Typography>
          <Typography>Sync updates from CA App</Typography>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => loadData()}
            disabled={loading}
          >
            <RefreshIcon style={{ marginRight: theme.spacing(1) }} />
            Refresh
          </Button>
        </div>
      </div>
      <Divider
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
      />
      <ContainerLoading show={loading} />
      <TableContainer>
        <Table
          style={{
            marginTop: theme.spacing(2),
            display: loading ? "none" : null
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Configuration Name</TableCell>
              <TableCell align="left">Collector Code</TableCell>
              <TableCell align="left">Last Updated</TableCell>
              <TableCell align="left">Settings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configurationList.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.collectorCodeExclusive}</TableCell>
                <TableCell align="left">
                  {moment
                    .utc(row.lastAccessed)
                    .local()
                    .format("hh:mma MMM-DD, YYYY Z")}
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    onClick={() => EditSettings(index)}
                  >
                    <RefreshIcon style={{ marginRight: theme.spacing(1) }} />
                    Settings
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditDialog
        open={editDialogIsOpened}
        editDialogData={editDialogData}
        editDialogIndex={editDialogIndex}
        handleClose={() => {
          setEditDialogIsOpened(false);
        }}
      />
    </Grid>
  );
}

export default Configuration;

function isKeyNameValid(name) {
  if (!name) return false;

  return KEY_NAME_REGEX.test(name);
}

function isKeyNameDuplicate(keys, name) {
  return keys.map(key => key.name).includes(name);
}

function getKeyNameHelperText(keys, name) {
  if (!isKeyNameValid(name)) {
    return "Invalid name";
  }

  if (isKeyNameDuplicate(keys, name)) {
    return "Name already in use";
  }

  return "";
}
