import * as React from "react";
import Add from "@mui/icons-material/Add";
import Circle from "@mui/icons-material/Circle";
import Delete from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import { formatDate } from "../../../../utils/misc";

import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  colors,
  Paper,
  IconButton,
  TextField
} from "@mui/material";

function KioskRowErrorLink({ syncErrorRef, locationCode }) {
  if (syncErrorRef === undefined || syncErrorRef === null) {
    return <></>;
  }

  const params = new URLSearchParams({
    inboundCode: locationCode
  });

  return (
    <Button
      variant="outlined"
      color="error"
      href={`/dashboard/ErrorLogs/${syncErrorRef}?${params.toString()}`}
    >
      Error
    </Button>
  );
}

export default KioskRowErrorLink;
