import _ from "lodash";
import axios from "axios";
import jwtDecode from "jwt-decode";

import { Dlog } from "./Dlog";

let env = require("./../env.js");

const API_ENDPOINT = env.REACT_APP_API_ENDPOINT;

export const get = async (url, params) => {
  try {
    let accessToken = localStorage.getItem("accessToken");

    Dlog("DEBUG: GET api.js", accessToken);

    if (!isAuthEndpoint(url) && (!accessToken || isTokenExpired(accessToken))) {
      accessToken = await getNewToken();
    }

    const res = await axios({
      method: "GET",
      url: API_ENDPOINT + url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params,
      withCredentials: isLogoutEndpoint(url) // Need to pass cookie back for logging out
    });

    Dlog("DEBUG", res);

    return res;
  } catch (err) {
    console.error(err);

    const response = _.get(err, "response");
    if (response) {
      return response;
    }

    return { status: 400 };
  }
};

export const post = async (
  url,
  data,
  type = "application/x-www-form-urlencoded"
) => {
  try {
    let accessToken = localStorage.getItem("accessToken");

    Dlog("DEBUG: POST api.js", data, accessToken);

    if (!isAuthEndpoint(url) && (!accessToken || isTokenExpired(accessToken))) {
      accessToken = await getNewToken();
    }

    const res = await axios({
      method: "POST",
      url: API_ENDPOINT + url,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": type
      },
      withCredentials: isAuthEndpoint(url)
    });

    Dlog("DEBUG", res);

    return res;
  } catch (err) {
    console.error(err);

    const response = _.get(err, "response");
    if (response) {
      return response;
    }

    return { status: 400 };
  }
};

export const simpleget = async (url, params) => {
  try {
    Dlog("DEBUG: simple GET api.js");

    const res = await axios({
      method: "GET",
      url: API_ENDPOINT + url,
      params
    });

    Dlog("DEBUG", res);

    return res;
  } catch (err) {
    console.error(err);

    const response = _.get(err, "response");
    if (response) {
      return response;
    }

    return { status: 400 };
  }
};

export const simplepost = async (
  url,
  data,
  type = "application/x-www-form-urlencoded"
) => {
  try {
    Dlog("DEBUG: simple POST api.js", data);

    const res = await axios({
      method: "POST",
      url: API_ENDPOINT + url,
      data,
      headers: {
        "Content-Type": type
      }
    });

    Dlog("DEBUG", res);

    return res;
  } catch (err) {
    console.error(err);

    const response = _.get(err, "response");
    if (response) {
      return response;
    }

    return { status: 400 };
  }
};

function isAuthEndpoint(url) {
  return (
    url == "/login" ||
    url == "/register" ||
    url == "/forgetpassword" ||
    url == "/forgetpasswordsent" ||
    url == "/resetpassword"
  );
}

function isLogoutEndpoint(url) {
  return url == "/logout";
}

function isTokenExpired(token) {
  const decodedToken = jwtDecode(token);
  const expirationDate = new Date(decodedToken.exp * 1000);

  return new Date() > expirationDate;
}

async function getNewToken() {
  const authenticationRes = await axios({
    method: "get",
    url: API_ENDPOINT + "/refreshAuth",
    withCredentials: true
  });

  if (authenticationRes.status != 200) {
    throw new Error("An authentication error occurred");
  }

  const accessToken = _.get(authenticationRes, "data.accessToken");
  if (!accessToken) {
    throw new Error("An authentication error occurred");
  }

  localStorage.setItem("accessToken", accessToken);
  return localStorage.getItem("accessToken");
}
