// import * as React from "react";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";

import LocationObjectDisplay from "./LocationObjectDisplay";

import { Autocomplete, TextField, Snackbar, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AlertDialog from "../../../../components/Dialogs/AlertDialog";

import HttpContext from "../../../../contexts/HTTP/HttpContext";

import useSnackbar from "./../../../../hooks/useSnackbar";

function CreateKioskGroupDialog(props) {
  const theme = useTheme();
  const { post, get } = useContext(HttpContext);

  const [locationcode, setLocationcode] = useState("");

  const [locationobject, setLocationobject] = useState(null);

  const {
    row,
    setShowDeleteKioskDialog,
    setKioskObjectToDelete,
    open,
    handleConfirm,
    handleClose,
    disableConfirm,
    value,
    onChange,
    error,
    helperText,
    locationOptions,
    locationList,
    locationsLoading,
    locationsRefreshFunc
  } = props;

  const { openSnackbar } = useSnackbar();

  const changeLocationCombo = async (evt, newlocation) => {
    console.log("changeLocationCombo newlocation", newlocation);

    //Find info from locationOptions
    let locationOption = locationOptions.find(l => {
      return l.code == newlocation;
    });
    console.log("changeLocationCombo locationOption", locationOption);
    setLocationobject(locationOption);

    setLocationcode(newlocation);
  };

  const generateKioskGroup = async () => {
    if (
      locationobject == null ||
      locationobject.code == null ||
      locationobject.code == ""
    ) {
      openSnackbar({
        open: true,
        message: "Invalid location code",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
      return;
    }

    props.setLoading(true);

    const res = await post("/kiosk/createSubscription", {
      schemaVersion: 3,
      maxKiosks: -1,
      locationCode: locationobject.code,
      locationName: locationobject.name,
      locationAddress: locationobject.address,
      subscriptionID: locationobject.key,
      kioskKeys: []
    });
    if (res.status === 200) {
      openSnackbar({
        open: true,
        message: "Added successfully",
        variant: "alert",
        alert: {
          color: "success"
        },
        close: false
      });
    } else {
      openSnackbar({
        open: true,
        message: "Duplicate entry - already exists",
        variant: "alert",
        alert: {
          color: "error"
        },
        close: false
      });
    }

    await props.loadKeys();

    props.setLoading(false);
    handleClose();
  };

  return (
    <>
      <AlertDialog
        open={open}
        handleConfirm={generateKioskGroup}
        handleClose={handleClose}
        title="Add a Kiosk Location"
        confirmButtonName="Confirm"
        closeButtonName="Cancel"
      >
        <br />
        <Autocomplete
          disablePortal
          id="cblocationoptions"
          options={locationList}
          onChange={changeLocationCombo}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              name="name"
              label="Location Code"
              style={{ marginBottom: theme.spacing(2) }}
            />
          )}
        />
        <LocationObjectDisplay Locationobject={locationobject} />
      </AlertDialog>
    </>
  );
}

export default CreateKioskGroupDialog;
