import React, { useEffect, useContext, useState } from "react";
import _ from "lodash";
import { Route, Routes, Navigate } from "react-router-dom";

let env = require("./../../../env");

//Component is the element
// redirect is a string route to redirect to, if its prod
function CheckWebAccessRoute(props) {
  //console.log(props.Component);
  const { Component, redirect } = props;

  //TODO: If not a device && WebAccess

  const WebAccessEnabled = false;
  if (WebAccessEnabled) {
    return <Navigate to={redirect} />;
  }

  return Component;
}

export default CheckWebAccessRoute;
