import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Translate, Localize, getLocale } from "react-i18nify";

import { kioskstyles } from "./../../../../kioskstyles";

import { StepConnector } from "@mui/material";

// props has activeStep
export default function KioskStepper({
  activeStep,
  workflowmode,
  backUrl,
  getKioskConfiguration
}) {
  const navigate = useNavigate();

  const [steps, setSteps] = useState(["", "", "", ""]);

  // workflowmode should work as well, can refactor to this if needed
  // useEffect(() => {
  //   if (props.workflowmode == "bag") {
  //     setSteps(["", "", ""]);
  //   } else {
  //     setSteps(["", "", "", ""]);
  //   }
  // }, []);
  useEffect(() => {
    let kc_workflowmode = getKioskConfiguration().workflowMode;
    if (kc_workflowmode === "bag") {
      setSteps(["", "", ""]);
    } else {
      setSteps(["", "", "", ""]);
    }
  }, []);

  const pressBack = () => {
    if (backUrl !== undefined) {
      navigate(backUrl);
    }
  };

  const pressEnd = () => {
    navigate("/language");
  };

  return (
    <div
      style={{
        maxWidth: "490px", //Same as blackCornedDiv
        width: "41vw" //TODO: use minWidth, if mobile
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          disabled={backUrl == undefined}
          onClick={pressBack}
          style={kioskstyles.StepperButtonBack}
        >
          <KeyboardBackspaceIcon
            fontSize="small"
            style={{ marginRight: "10px" }}
          />
          {/* &nbsp; &nbsp; */}
          <span class="fontSecondaryText" style={{}}>
            <Translate value="application.back" />
          </span>
        </Button>
        <Stepper
          activeStep={activeStep}
          style={{ width: "50%", paddingLeft: "8px", paddingRight: "8px" }}
          connector={<StepConnector />}
        >
          {steps.map((label, index) => (
            <Step
              key={index}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <StepButton color="inherit" style={{}}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Button
          variant="contained"
          onClick={pressEnd}
          style={kioskstyles.StepperButtonEnd}
        >
          <CancelIcon fontSize="small" style={{ marginRight: "10px" }} />
          <span class="fontSecondaryText" style={{}}>
            <Translate value="application.end" />
          </span>
        </Button>
      </Box>
    </div>
  );
}
